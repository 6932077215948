import { GetPublicSource } from "../Common/Helpers";
import Icons from "../Common/Icons";

const EverythingSection = () => {
    return (
        <section className="section py-5">
            <div className="container pt-6 pb-4 text-center">
                <h2 className="mb-3">Screens offer everything you will need</h2>
                <p className="text-muted mb-5 lead">Create and maintain your digital signages from anywhere with our cloud-based CMS. You can add slideshows, information, scrolling news, weather, social media feeds, event listings, HD videos, and more using just drag-and-drop widgets.</p>

                <div className="row justify-content-center mb-5">
                    <div className="col-12 col-md-6">
                        <div className="mock-tv d-inline-block ratio ratio-16x9">
                            <a data-fancybox="powereditor" className="video-link" href={`${GetPublicSource('/assets/videos/hospitality-layout.mp4')}`}>
                                <img className="card-img-top img-fluid" src={`${GetPublicSource('/assets/images/poweredito-screenshot.JPG')}`}></img>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="svg-icon lg">
                            <Icons icon="IconCloudbased" />
                        </div>
                        <h4 className="mb-3">Cloud-Based</h4>
                        <p className="text-muted">Cloud-based content manager allows for instant content updates, anytime, anywhere.</p>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="svg-icon lg">
                            <Icons icon="IconPowerEditor" />
                        </div>
                        <h4 className="mb-3">Power Editor</h4>
                        <p className="text-muted">Accessible from any computer, Drag &amp; Drop, no deisgn or technical skills required.</p>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="svg-icon lg">
                            <Icons icon="IconDisplayFlex" />
                        </div>
                        <h4 className="mb-3">Display Flexibility</h4>
                        <p className="text-muted">Set your display dimensions and Screens will seamlessly work with any screen resolution and aspect ratio.</p>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="svg-icon lg">
                            <Icons icon="IconWidgets" />
                        </div>
                        <h4 className="mb-3">Widgets</h4>
                        <p className="text-muted">Slidwshows, Tickers, Weather, Social, RSS. Number of ready to use components for you.</p>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="svg-icon lg">
                            <Icons icon="IconSchedule" />
                        </div>
                        <h4 className="mb-3">Multi-Layered Scheduling</h4>
                        <p className="text-muted">Set play duration, pre-schedule repeat or one-time play. And update within seconds.</p>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="svg-icon lg">
                            <Icons icon="IconMultimedia" />
                        </div>
                        <h4 className="mb-3">Multi-Media Support</h4>
                        <p className="text-muted">Images, videos, audios, we support all most all common media formats.</p>
                    </div>
                    {/* <div className="col-12 col-md-4">
                        <div className="svg-icon lg">
                            <Icons icon="IconIntegrations" />
                        </div>
                        <h4 className="mb-3">Integrations</h4>
                        <p className="text-muted">Connect with Calendars, CSV, XLS, XML Feeds and custom APIs.</p>
                    </div> */}
                </div>
            </div>
        </section>
    );
}

export default EverythingSection;