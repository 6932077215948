
import FaqSection from "../../../components/SectionFAQ";

import HeroImage from '../../../assets/hero-images/Restaurant-Hero-IMG.png';
import PageHeader from "../../../components/Common/PageHeader";
import ReadyStart from "../../../components/Common/ReadyStart";
import PageDescription from "../../../components/Common/PageDescription";
import SectionBox from "../../../components/Common/SectionBox";
import Icons from "../../../components/Common/Icons";
import Curve from "../../../components/Curve";

const Restaurants = () => {
    return (
        <>
            <PageHeader
                title="Digital Signage for Restaurants and Cafe's"
                text="Screens digital signage can help your business stand out while also increasing category sales and providing a better dining experience for your patrons."
                image = {< img src = { HeroImage } className = "img-fluid" />} />

            <PageDescription title="Enhance your restaurant's brand experience and connect with your patrons.">
                <p>One of the most commonly asked questions during mealtime is “What should we eat?”. Having to decide what to eat is really more complicated than it looks and most people will gladly follow suggestions made by their friends or total strangers (food bloggers). </p>
                <p className="mb-0">Create and promote a “Today’s Special Set Meal” instead of just a generic menu, not only will your customers be attracted with scrumptious food, you will find them coming back more often as they are enticed to the food shown in the screens. With digital screens, you will be able to sell more meals as food simply look more appetizing on screens.</p>
            </PageDescription>


            <section className="section py-5">
                <div className="container pt-6 pb-4">

                    <div className="row">

                        <div className="col-12 col-md-6 order-md-2">
                            <div className="px-1 px-md-4">
                                <h2 className="mb-3">5 Reasons why you need Digital Signage in Restaurants</h2>
                                <ul>
                                    <li className="py-2">
                                        <strong className="d-block">Upsell</strong>
                                        At peak traffic hours, strategically schedule new items, desserts, and other items to play on.
                                    </li>
                                    <li className="py-2">
                                        <strong className="d-block">Convenience</strong>
                                        You'll never have to manually delete and update pricing or items again.
                                    </li>
                                    <li className="py-2">
                                        <strong className="d-block">Flexibility</strong>
                                        Add new menu specials, drink specials, and promotions with ease.
                                    </li>
                                    <li className="py-2">
                                        <strong className="d-block">Engagement</strong>
                                        Rotating promotions that are pre-scheduled attract attention and increase sales.
                                    </li>
                                    <li className="py-2">
                                        <strong className="d-block">Control</strong>
                                        Give your managers access to the entire menu or just a few sections of it.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 order-md-1 text-center">
                            <div className="px-1 px-md-4">
                                <div className="mb-5">
                                    <img src={`${process.env.PUBLIC_URL}/assets/concept-images/Restaurant-IMG.jpg?v=${process.env.REACT_APP_VERSION || '0.0.1'}`} className="img-fluid rounded shadow" />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            <section className="section py-5">
                <div className="container py-6 pb-4 text-center">
                    <h2 className="mb-3">Screens is here for you</h2>
                    <p className="mb-5 lead">You can use Screens to strategically pre-schedule your limited-time offers, new appetiser deals, dessert specials, and other promotions to play automatically during your busiest traffic times.</p>

                    <div className="row">
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Digital Menus"
                                text="Create and modify your menu and its layout with ease. You can improve the menu on a monthly basis based on customer feedback and sales."
                                icon={<Icons icon="RestaurantMenus" />} />
                        </div>

                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Loyalty Programmes"
                                text="Set aside a section of the screen to solely promote and remind customers to join your value-added loyalty programme or app."
                                icon={<Icons icon="RestaurantLoyal" />} />

                        </div>
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Nutrition Information"
                                text="Attract the 'numbers sensitive' audiance when u can easily display item's calories, total fat, total carbs, and protein content."
                                icon={<Icons icon="RestaurantInfo" />} />

                        </div>
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Promote High Margin LTOs"
                                text="According to studies, people look at the upper right corner of a menu first, making it the ideal place to put your specials and promotions."
                                icon={<Icons icon="RestaurantPromo" />} />
                        </div>
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Drinks Specials"
                                text="Dedicate a single screen to your highest-margin drinks, such as seasonal margaritas, new Long Island iced teas, or beer sales."
                                icon={<Icons icon="RestaurantDrinks" />} />
                        </div>
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Social Media Presence"
                                text="Encourage your customers to take photos of their entries and post them on social media using your company's hashtag. On your display, all posts will be dynamically posted."
                                icon={<Icons icon="RestaurantScial" />} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section py-5 mt-5">
                <Curve position="slope-right" className="color-secondary" />
            </section>

            <section className="section py-5 bg-light">
                <div className="container pb-5">
                    <div className="row align-items-start">

                        <div className="col-12 col-md-6">
                            <div className="px-1 px-md-4">
                                <h2 className="mb-3">Wait... Not Conviced Yet?</h2>
                                <p className="lead mb-2">Here are some additional benefits:</p>
                                <ul>
                                    <li className="py-2">
                                        <strong className="d-block">Appealing</strong>
                                        Use the fact that people are conditioned to look at televisions to your advantage.
                                    </li>
                                    <li className="py-2">
                                        <strong className="d-block">Effortless</strong>
                                        Edit and update your menu from any computer, at any time.
                                    </li>
                                    <li className="py-2">
                                        <strong className="d-block">Branding</strong>
                                        Consistent messaging across all screens fosters brand loyalty and recognition.
                                    </li>
                                    <li className="py-2">
                                        <strong className="d-block">Eye-Catching</strong>
                                        It's easier to read and engage with active colours and a well-ordered menu.
                                    </li>
                                    <li className="py-2">
                                        <strong className="d-block">Recognition</strong>
                                        Customer loyalty and brand recall are improved by consistent branding.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 text-center">
                            <div className="px-1 px-md-4">
                                <img src={`${process.env.PUBLIC_URL}/assets/concept-images/Layout-Menu-Board.png?v=${process.env.REACT_APP_VERSION || '0.0.1'}`} alt="" className="img-fluid" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <ReadyStart />

            <FaqSection />
        </>
    );
}
 
export default Restaurants;