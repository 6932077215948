import { useEffect, useReducer } from "react";

import './index.scss';

import { ReactComponent as HeroImage } from '../../assets/products-hero.svg';
import PageHeader from "../../components/Common/PageHeader";
import PageDescription from '../../components/Common/PageDescription';
import ProductsList from '../../services/ProductList';
import ProductAddons from "../../services/ProductAddon";
import FaqSection from "../../components/SectionFAQ";
import Curve from "../../components/Curve";

import Wave from "../../components/Wave";
import ProductCell from "../../components/Common/ProductCell";
import ReadyStart from "../../components/Common/ReadyStart";


const ProductsPage = () => {

    const [state, setState] = useReducer((state, newState) => ({
        ...state,
        ...newState
    }), {
        productsList: [],
        productsAddons: {},
    });

    useEffect(() => {
        ProductsList().then((resp) => setState({ productsList: resp }));
        ProductAddons().then((resp) => setState({ productsAddons: resp }));
    }, []);

    return (
        <>
            <PageHeader
                title="Simply The Best"
                text="We bundle the best software with the most reliable and robust digital signage hardware to deliver the user-experience you deserve."
                image={<HeroImage />} />

            <PageDescription title="The Best Digital Signage Solution on the Market">
                <p>With our software, you can create inspiring content and create vibrant displays. It is user-friendly, yet powerful enough to deploy and manage across multiple locations.</p>
                <p className="mb-0">We have hand-picked the most cost-effective, most robust, and most reliable digital signage hardware to best suit your needs.</p>
            </PageDescription>

            <section className="section mt-5 pt-5">
                <Wave style="style-1" absolute="true" className="color-secondary" />
            </section>

            <section className="section pb-5 bg-light">

                <div className="container pb-6">

                    <h2 className="mb-4">Our Products</h2>
                    <div className="row row-cols-1 row-cols-md-3 g-5 mb-5">
                        {
                            state.productsList.map((item, index) => (
                                <div className="col" key={index}>
                                    <ProductCell
                                        key={index}
                                        index={0}
                                        icon={item.image}
                                        url={`/products/${item.id}`}
                                        title={item.title}
                                        text={item.text} />
                                </div>
                            ))
                        }
                    </div>

                </div>
            </section>

            <section className="section bg-light pb-5">
                <div className="container py-5">
                    <div className="row align-items-center mt-3 mt-md-0">

                        <div className="col-lg-4 col-md-5 text-center text-md-start mb-5 mb-md-0">
                            <h2 className="mb-3">Add On Products</h2>
                            <p className="text-muted mb-5 lead">We also offer crucial Add-ons so that you don't have to worry.</p>
                        </div>

                        <div className="col-lg-8 col-md-7 d-flex align-items-center flex-column bg-shape">

                            <div className="row row-cols-1 row-cols-md-3 g-5 align-items-center justify-content-center">
                                {
                                    Object.keys(state.productsAddons).map((key, index) => (
                                        <div className="col" key={index}>
                                            <ProductCell
                                                key={index}
                                                index={0}
                                                icon={state.productsAddons[key].image}
                                                title={state.productsAddons[key].title}
                                                text={state.productsAddons[key].text} />
                                        </div>
                                    ))
                                }
                            </div>

                        </div>

                    </div>
                </div>
            </section>

            {/* <section className="section">
                <Curve position="bottom" className="color-body" />
            </section> */}

            <ReadyStart />

            <FaqSection />
        </>
    );
}

export default ProductsPage;



