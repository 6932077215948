
import HeroImage from '../../../assets/hero-images/Covid-19_hero_image.png';
import { GetPublicSource } from '../../../components/Common/Helpers';
import PageDescription from "../../../components/Common/PageDescription"
import PageHeader from "../../../components/Common/PageHeader"
import ProductSpecList from '../../../components/Common/ProductSpecList';
import ReadyStart from '../../../components/Common/ReadyStart';
import FaqSection from '../../../components/SectionFAQ';
import Wave from '../../../components/Wave';


const CovidSOP = () => {
    return (
        <>
            <PageHeader
                title="Covid 19 SOPs"
                text="Companies and organizations must ensure that customers and employees are being updated on latest SOPs in the fastest way possible."
                image={<img src={HeroImage} className="img-fluid" />} />

            <PageDescription title="Digital Signage can help save lives.">
                <p>Digital Signage that is well-planned and well-placed can assist you in guiding customers across your premises, reducing unnecessary face-to-face contacts and providing information to improve defined paths.</p>
                <p className="mb-0">A key component of managing the measures surrounding Covid-19 is keeping both employees and customers well informed.</p>
            </PageDescription>



            <section className="section py-5">
                <div className="container py-6 pb-4 text-center bg-overlay-content">
                    <h2 className="mb-3">Screens is here for you</h2>
                    <p className="mb-5 lead">Screens can be accessed from anywhere, so even if you’re working from home it’s possible to send the right message in the right place at the right time.</p>

                    <div className="row row-cols-1 row-cols-md-3 g-5 pt-4">
                        <div className="col">
                            <img src={GetPublicSource('/public/assets/images/encourage_proper_hygiene.png')} alt="" className="img-fluid" />
                            <h5 className="text-capitalize mb-3">Encourage proper hygiene</h5>
                        </div>

                        <div className="col">
                            <img src={GetPublicSource('/public/assets/images/provide_emergency_contact_information.png')} alt="" className="img-fluid" />
                            <h5 className="text-capitalize mb-3">Provide emergency contact information</h5>
                        </div>

                        <div className="col">
                            <img src={GetPublicSource('/public/assets/images/promote_community_actions.png')} alt="" className="img-fluid" />
                            <h5 className="text-capitalize mb-3">Promote community actions</h5>
                        </div>

                        <div className="col">
                            <img src={GetPublicSource('/public/assets/images/reinforce_rules_and_regulation.png')} alt="" className="img-fluid" />
                            <h5 className="text-capitalize mb-3">Reinforce rules and regulations</h5>
                        </div>

                        <div className="col">
                            <img src={GetPublicSource('/public/assets/images/keep_everyone_upto_speed.png')} alt="" className="img-fluid" />
                            <h5 className="text-capitalize mb-3">Keep everyone up to speed</h5>
                        </div>

                        <div className="col">
                            <img src={GetPublicSource('/public/assets/images/show_appreciation.png')} alt="" className="img-fluid" />
                            <h5 className="text-capitalize mb-3">Show appreciation</h5>
                        </div>

                    </div>
                </div>
            </section>

            <section className="section">
                <Wave style="style-5" absolute={true} className="color-secondary" />
            </section>
            
            <section className="section py-5 bg-light">
                <div className="container pt-6 pb-4">

                    <div className="row g-md-6 align-items-center mb-5">
                        <div className="col-12 col-md-6">
                            <h2 className="mb-3">Notify People</h2>
                            <p className="lead mb-4">Screens have the advantage of delivering timely visual messaging via digital signage systems.</p>
                            <h5 className="mb-3">Key benefits:</h5>
                            <ProductSpecList list={[
                                    {text: 'React in real-time'},
                                    {text: 'Inform customers or employees of breaches'},
                                    {text: 'Prevent exposing employees or customers to unnecessary risk'},
                                ]} />
                        </div>
                        <div className="col-12 col-md-6">
                            <img src={ GetPublicSource('/assets/images/covid-sop-notify.jpg') } alt="" className="img-fluid rounded shadow" />
                        </div>
                    </div>

                    <div className="row g-md-6 align-items-center mb-5">
                        <div className="col-12 col-md-6 order-md-2">
                            <h2 className="mb-3">Instant Updates</h2>
                            <p className="lead mb-4">As government advice and guidelines continue to change on a regular basis, Screens is ideal as you can update the devices in a matter of seconds, either via USB stick or using our cloud based CMS.</p>
                            <h5 className="mb-3">Key benefits:</h5>
                            <ProductSpecList list={[
                                { text: 'Easy access and easy updates' },
                                { text: 'Online and offline publish' },
                                { text: 'Always up to date with latest SOPs and Guidlines' },
                            ]} />
                        </div>
                        <div className="col-12 col-md-6 order-md-1">
                            <img src={GetPublicSource(`/assets/images/covid-sop-remind.jpg`) } alt="" className="img-fluid rounded shadow" />
                        </div>
                    </div>

                    <div className="row g-md-6 align-items-center mb-5">
                        <div className="col-12 col-md-6">
                            <h2 className="mb-3">Portable Solution</h2>
                            <p className="lead mb-4">Screens 'standee' displays have integrated castors so the entire unit can be easily moved to any location as required.</p>
                            <h5 className="mb-3">Key benefits:</h5>
                            <ProductSpecList list={[
                                { text: 'Mobile and Free-Standing' },
                                { text: 'Anywhere and anytime you want' },
                                { text: 'Sleek and modern designs' },
                            ]} />
                        </div>
                        <div className="col-12 col-md-6">
                            <img src={GetPublicSource('/assets/images/covid-sop-portable.jpg') } alt="" className="img-fluid rounded shadow" />
                        </div>
                    </div>

                    <div className="row g-md-6 align-items-center mb-5">
                        <div className="col-12 col-md-6 order-md-2">
                            <h2 className="mb-3">Guide Crowds</h2>
                            <p className="lead mb-4">To avoid close contact and the risk of congestion, you need to control movement of the customers and your employees. With the help of Screens, you can create pathways and show direction signs to your customers and employees while also reminding them of social distancing guidelines.</p>
                            <h5 className="mb-3">Key benefits:</h5>
                            <ProductSpecList list={[
                                { text: 'Guide people through your location' },
                                { text: 'Avoid unnecessary face-to-face interactions' },
                                { text: 'Help remind the SOPs and guilines' },
                            ]} />
                        </div>
                        <div className="col-12 col-md-6 order-md-1">
                            <img src={GetPublicSource('/assets/images/covid-sop-guide.jpg')} alt="" className="img-fluid rounded shadow" />
                        </div>
                    </div>

                </div>
            </section>

            <ReadyStart />

            <FaqSection />
        </>
    );
}
 
export default CovidSOP;