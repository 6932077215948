import { useEffect, useReducer, useRef } from "react";

import './index.scss';

import { ReactComponent as HeroImage } from '../../assets/Choose plan image 2 SVG OMG.svg';
import PageHeader from "../../components/Common/PageHeader";
import ProductsList from '../../services/ProductList';
import ProductAddons from "../../services/ProductAddon";
import FaqSection from "../../components/SectionFAQ";
import Curve from "../../components/Curve";

import OrderDeviceList from "../../components/OrderItems/DeviceList";
import GrandSummary from "../../components/OrderItems/GrandSummary";
import PaymentTerms from "../LegalPage/PaymentTerms";
import Wave from "../../components/Wave";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
// import StepThree from "./StepThree";
import { isProductionServer, ValidateEmail } from "../../components/Common/Helpers";
import StepThree from "./StepThree";
import Wizard from "../../components/Wizard";


const registerURL = isProductionServer() ? 'https://www.screens.my/backend/service/register/' : 'https://projects.ounch.com/screens/backend/service/register/';

const PricingPage = () => {

    const eleWave = useRef(null);
    const currency = 'MYR';

    const [state, setState] = useReducer((state, newState) => ({
        ...state,
        ...newState
    }), {
        step: 1,
        numDevices: 0,
        selectedDevices: [],
        selectedAddons: [],
        productsList: [],
        productsAddons: {},
        isCheckingOut: false
    });
    
    const [userState, setUserState] = useReducer((state, newState) => ({
        ...state,
        ...newState
    }), {
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        phone: '',
    });
    
    const [infoState, setInfoState] = useReducer((state, newState) => ({
        ...state,
        ...newState
    }), {
        address_one: '',
        address_two: '',
        address_city: '',
        address_state: 'Selangor',
        address_postcode: '',
        isSameAddress: true,
        billing_one: '',
        billing_two: '',
        billing_city: '',
        billing_state: 'Selangor',
        billing_postcode: ''
    });

    useEffect(() => {
        ProductsList().then((resp) => setState({ productsList: resp }));
        ProductAddons().then((resp) => setState({ productsAddons: resp}));
    }, []);

    const onAddDevice = (device) => {
        const currentDevices = device.type === 'product' ? [...state.selectedDevices] : [...state.selectedAddons];

        // Get Curent Item if Any
        const item = currentDevices.filter((itm) => (itm.id === device.id));

        if(item.length){
            
            currentDevices.forEach((itm, index) => {
                if(itm.id === device.id){
                    itm.qty = parseInt(itm.qty) + 1
                }
            });

            saveSelectedLists(device.type, currentDevices);
            
        } else {
            const productInfo = device.type === 'product' ? state.productsList.filter((itm) => (itm.id === device.id)) : [state.productsAddons[device.id]];
            const newItem = {
                id: productInfo[0].id,
                title: productInfo[0].title,
                image: productInfo[0].image,
                price: productInfo[0].price,
                stripe: productInfo[0].stripe,
                qty: 1
            }

            currentDevices.push(newItem);

            saveSelectedLists(device.type, currentDevices);
        }
    }

    const saveSelectedLists = (type, data) =>{
        const saveObj = {}
        saveObj[type === 'product' ? 'selectedDevices' : 'selectedAddons'] = data;

        setState(saveObj);

        if (type === 'product' && data.length < 1 && state.selectedAddons.length){
            setState({ selectedAddons: []})
        }
    }

    const onStep = (step) => {
        window.scrollTo(0, eleWave.current.offsetTop - 80);
        setTimeout(() => {
            setState({step: step });
        }, 500);
    }

    const onCheckout = (data) => {
        const stripeJson = [];
        const { selectedDevices , selectedAddons } = state;

        setState({ isCheckingOut: true });

        if(selectedDevices.length){
            selectedDevices.forEach((item) => {
                // Push Hardware
                stripeJson.push({
                    ...item.stripe.hardware,
                    qty: item.qty,
                    name: window.btoa(item.title + ' - Hardware')
                });

                // Push Software
                stripeJson.push({
                    ...item.stripe.software,
                    qty: item.qty,
                    name: window.btoa(item.title + ' - Software')
                });
            })
        }

        if(selectedAddons.length){
            selectedAddons.forEach((item) => {
                stripeJson.push({
                    ...item.stripe, 
                    qty: item.qty,
                    name: window.btoa(item.title + ' - Addon')
                })
            })
        }

        const result = {
            stripeData: stripeJson,
            amountInfo: [{
                ...data,
                currency: currency
            }],
            userInfo: { ...userState },
            shippingInfo: {...infoState }
        }

        // Create Base64 String
        const base64 = window.btoa(JSON.stringify(result));

        // console.log(JSON.stringify(result, '', 4));
        // console.log(registerURL + data.type + '/' + base64);

        setTimeout(() => {
            window.location.href = registerURL + data.type + '/' + base64;
        }, 1000);

    }

    const onUserDetailsChange = (target, value) => {
        if(target === 'email'){
            setUserState({ email: ValidateEmail(value) ? value : '' })
        } else {
            setUserState({ [target]: value })
        }
    }
    
    const onInfoDetailsChange = (target, value) => {
        setInfoState({ [target]: value })
    }


    const switchStep = () => {
        switch (state.step) {
            case 2:
                return (
                    <StepTwo
                        data={ userState }
                        onChange={ onUserDetailsChange } 
                        onStep={ onStep }
                    />
                )

            case 3:
                return (
                    <StepThree
                        data={ infoState }
                        onChange={ onInfoDetailsChange }
                        onStep={ onStep }
                        isCheckingOut={ state.isCheckingOut }
                    />
                )

            default:
                return (
                    <StepOne
                        currency={currency}
                        productsList={state.productsList}
                        productsAddons={state.productsAddons}
                        selectedDevices={state.selectedDevices}
                        onAddDevice={onAddDevice}
                    />
                )
        }
    }

    return (
        <>
            <PageHeader
                title="Smart Digital Signage"
                text="Choose the device that best suits to your needs. Grow your business with the right digital signage!"
                image={<HeroImage/>} 
                showButton={false} />


            <section ref={eleWave} className="section mt-5 pt-5">
                <Wave style="style-7" absolute="true" className="color-secondary" />
            </section>

            <Wizard 
                steps={[
                    'Step 1. Choose Your Devices.',
                    'Step 2. Account Information.',
                    'Step 3. Billing & Shipping Information.'
                ]}
                currentStep={state.step} />

            <section className="section pb-5 mt-n5 bg-light">
                
                <div className="container py-6">

                    <div className="row mb-5">
                        <div className="col-12 col-md-8 mb-5 mb-md-0">
                            <div className="bg-white box-shadow-lg rounded-3 card border-0">
                                <div className="card-body p-md-5">

                                    { switchStep() }

                                </div>

                            </div>
                        </div>

                        <div className="col-12 col-md-4">

                            <div className="bg-white box-shadow-lg rounded-3 card border-0">
                                
                                <div className={`card-body p-md-5 ${state.isCheckingOut ? 'pe-none opacity-50': ''}`}>

                                    <h3 className="mb-4">Your cart</h3>

                                    {
                                        !state.selectedDevices.length && <div className="text-muted text-center border-top border-light pt-4">
                                            <i className="mdi mdi-cart-plus mdi-36px opacity-25"></i>
                                            <p className="lead">Your Cart is Empty</p>
                                        </div>
                                    }

                                    {state.selectedDevices.length ? 
                                        <OrderDeviceList 
                                            deviceList={state.selectedDevices} 
                                            onUpdate={(data) => saveSelectedLists('product', data)} 
                                        /> 
                                        : ''
                                    }

                                    {state.selectedAddons.length ? 
                                        <OrderDeviceList 
                                            deviceList={state.selectedAddons} 
                                            onUpdate={(data) => saveSelectedLists('addon', data)} 
                                        /> 
                                        : ''
                                    }


                                </div>

                                {state.selectedDevices.length ? 
                                    <GrandSummary
                                        currency={currency}
                                        deviceList={state.selectedDevices} 
                                        addonList={state.selectedAddons} 
                                        isCheckingOut={state.isCheckingOut}
                                        step={state.step}
                                        onStep={ onStep }
                                        btnCheckOutDisable={ userState.firstName === '' || userState.lastName === '' || userState.email === '' }
                                        btnPaymentDisable={ infoState.address_one === '' || infoState.address_city === '' || infoState.address_postcode === '' }
                                        onCheckout={ onCheckout }
                                    /> : ''}
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="container">
                            <div className="card border-0 bg-transparent">
                                <div className="card-body p-md-5">
                                    <PaymentTerms />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>

            <section className="section">
                <Curve position="bottom" className="color-body" />
            </section>

            <FaqSection />
        </>
    );
}
 
export default PricingPage;



