import SubscribeButton from "./SubscribeButton"


const PageHeader = ({ title, text, image, showButton=true}) => {
    return (
        <section className="section py-5 section-page-header d-flex align-items-center">
            <div className="container py-6">
                <div className="row align-items-center">
                    <div className="col-12 col-md-5 mt-lg-n7 mb-sm-3 mb-lg-0 text-start">
                        <h1 className="display-4 mb-3 pt-5">{title}</h1>
                        <p className="lead mb-5">{text}</p>

                        {showButton ? <SubscribeButton /> : ''}
                    </div>
                    <div className="col-12 col-md-7 d-none d-md-block px-0">
                        { image }
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default PageHeader;