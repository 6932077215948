
import './indexx.scss';

import { ReactComponent as Logo } from '../../assets/logo-screens-full.svg';
import Curve from '../Curve';
import { NavLink } from 'react-router-dom';

const MainFooter = () => {

    // console.log(process.env);
    
    return (
        <footer className="pb-3 bg-light section">
            <Curve position="top" className="color-body" />
            <div className="container pt-5">

                <div className="row">
                    <div className="col-12 col-md-3 mb-4 mb-md-0 text-center text-md-start">
                        <div className="footer-logo mb-3 mx-auto mx-md-0">
                            <Logo />
                        </div>
                        <p>Screens help to transform any TV into a powerful marketing tool, effectively capturing the attention of potential customers for you to communicate your deals, helping you convert traffic into sales.</p>

                    </div>

                    <div className="col-12 col-md-3 mb-4 mb-md-0">
                        <ul className="list-group list-group-flush ps-0 ps-md-3 text-center text-md-start">
                            <li className="list-group-item"><NavLink to="/">Home</NavLink></li>
                            <li className="list-group-item"><NavLink to="/products">Products</NavLink></li>
                            <li className="list-group-item"><NavLink to="/solutions/covid19">Solutions - Covid 19</NavLink></li>
                            <li className="list-group-item"><NavLink to="/solutions/retail">Solutions - Retail</NavLink></li>
                            <li className="list-group-item"><NavLink to="/solutions/education">Solutions - Education</NavLink></li>
                            <li className="list-group-item"><a href="//templates.screens.my" target="_blank" rel="noreferrer">Templates <i className="mdi mdi-call-made"></i></a></li>
                        </ul>
                    </div>

                    <div className="col-12 col-md-3 mb-4 mb-md-0">
                        <ul className="list-group list-group-flush ps-0 ps-md-3 text-center text-md-start">
                            <li className="list-group-item"><NavLink to="/legal/privacy">Privacy Policy</NavLink></li>
                            <li className="list-group-item"><NavLink to="/legal/terms">Terms &amp; Conditions</NavLink></li>
                            <li className="list-group-item"><NavLink to="/legal/eula">EULA</NavLink></li>
                            <li className="list-group-item"><NavLink to="/legal/returns">Warranty &amp; Returns</NavLink></li>
                            <li className="list-group-item"><NavLink to="/legal/shipping">Shipping &amp; Delivery</NavLink></li>
                            <li className="list-group-item"><NavLink to="/legal/lifetime">Lifetime Access</NavLink></li>
                        </ul>
                    </div>

                    <div className="col-12 col-md-3 mb-4 mb-md-0 text-center text-md-start">
                        <address>
                            <strong>Ounch Sdn Bhd,</strong> <br />
                            #06-01, Wisma Minlon <br />
                            Batu 12, Lebuhraya Sg Besi, <br />
                            43300 Seri Kembangan, <br />
                            Selangor, Malaysia. <br />
                            Tel: +6017 5050 722 <br />
                            Email: info@screens.my <br />
                        </address>

                        <ul className="list-unstyled text-muted mb-3 list-inline">
                            <li className="list-inline-item">
                                <a href="https://www.facebook.com/screens.my" target="_blank" rel="noreferrer" className="btn btn-light text-reset px-3">
                                    <i className="mdi mdi-facebook mdi-24px"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://twitter.com/ounchupdates" target="_blank" rel="noreferrer" className="btn btn-light text-reset px-3">
                                    <i className="mdi mdi-twitter mdi-24px"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://www.instagram.com/screens_my/" target="_blank" rel="noreferrer" className="btn btn-light text-reset px-3">
                                    <i className="mdi mdi-instagram mdi-24px"></i>
                                </a>
                            </li>
                        </ul>

                    </div>
                </div>
                <hr className="opacity-10" />
                <p className="text-center">&copy; 2020 - {new Date().getFullYear()} Ounch Sdn Bhd &bull; <span className="text-uppercase">All Rights Reserved</span> &bull; {process.env.REACT_APP_VERSION || '0.0.1'}</p>

            </div>
        </footer>
    );
}
 
export default MainFooter;