import './index.scss';

import {
    Switch,
    Route,
    NavLink,
    useRouteMatch,
    // useParams,
    Redirect
} from "react-router-dom";

import FaqSection from "../../components/SectionFAQ";
import Privacy from './Privacy';
import Terms from './Terms';
import EULA from './EULA';
import Returns from './Returns';
import Shipping from './Shipping';
import MBG from './MBG';
import Lifetime from './Lifetime';
import { useState } from 'react';


const LegalPage = () => {

    const match = useRouteMatch();
    const [title, setTitle] = useState('');
    
    return (
        <>
            <section className="section py-5 header-section d-flex align-items-center">
                <div className="container py-6 text-center">
                    <h1 className="display-4 mb-3 pt-4 pt-md-0">{title}</h1>
                    <p className="">Last updated: May 25, 2021.</p>
                </div>
            </section>

            <section className="section mb-5">
                <div className="container">
                    <div className="bg-white box-shadow-lg rounded-3 card border-0">
                        <div className="card-body">
                            <div className="row">

                                <div className="col-12 col-md-3 py-4">
                                    <div className="nav flex-column flex-fill nav-pills me-md-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <NavLink className="nav-link rounded-pill" to={`${match.url}/privacy`}>Privacy Policy</NavLink>
                                        <NavLink className="nav-link rounded-pill" to={`${match.url}/terms`}>Terms &amp; Conditions</NavLink>
                                        <NavLink className="nav-link rounded-pill" to={`${match.url}/eula`}>EULA</NavLink>
                                        <NavLink className="nav-link rounded-pill" to={`${match.url}/returns`}>Warranty &amp; Returns</NavLink>
                                        <NavLink className="nav-link rounded-pill" to={`${match.url}/shipping`}>Shipping &amp; Delivery</NavLink>
                                        <NavLink className="nav-link rounded-pill" to={`${match.url}/lifetime`}>Lifetime Access</NavLink>
                                    </div>
                                </div>

                                <div className="col-12 col-md-9">

                                    <div className="p-5">

                                        <Switch>
                                            <Route path={`${match.path}/:topicId`} render={(props) => {
                                                const { topicId } = props.match.params;
                                                
                                                switch (topicId) {
                                                    case 'privacy':
                                                        setTitle('Privacy Policy');
                                                        return <Privacy />;

                                                    case 'terms':
                                                        setTitle('Terms & Conditions');
                                                        return <Terms />;

                                                    case 'eula':
                                                        setTitle('End User License Agreement');
                                                        return <EULA />;

                                                    case 'returns':
                                                        setTitle('Warranty & Returns');
                                                        return <Returns />;

                                                    case 'shipping':
                                                        setTitle('Shipping & Delivery');
                                                        return <Shipping />;

                                                    case 'money-back':
                                                        setTitle('14 Days Money Back Guarantee');
                                                        return <MBG />;

                                                    case 'lifetime':
                                                        setTitle('Lifetime Access');
                                                        return <Lifetime />;

                                                    default:
                                                        break;
                                                }
                                                
                                                // return <Topic />
                                            }} />
                                                
                                            <Route path={match.path}>
                                                <Redirect to="legal/privacy" />
                                            </Route>
                                        </Switch>

                                    </div>
                                   

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
            <FaqSection />
        </>
    );
}

export default LegalPage;


/* function Topic() {
    const { topicId } = useParams();
    
    switch (topicId) {
        case 'privacy':
            return <Privacy />;
    
        case 'terms':
            return <Terms />;
    
        case 'eula':
            return <EULA />;
    
        case 'returns':
            return <Returns />;
    
        case 'shipping':
            return <Shipping />;
    
        case 'money-back':
            return <MBG />;
    
        default:
            break;
    }
} */