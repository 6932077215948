import ProductCard from "../../components/Common/ProductCard";


const StepOne = ({ currency, productsList, productsAddons, selectedDevices, onAddDevice}) => {
    return (
        <>
            <section className="mb-5">
                <div className="d-flex align-items-center mb-5">
                    <h3 className="p-0 m-0">Players & Standees</h3>
                </div>


                <div className="row row-cols-1 row-cols-md-3 g-4 justify-content-center">
                    {productsList.map((item, index) => (
                        <ProductCard
                            key={index}
                            id={item.id}
                            type='product'
                            image={item.image}
                            url={`/products/${item.id}`}
                            title={item.title}
                            text={`${item.text.substring(0, 50)} ...`}
                            {...item.meta}
                            price={item.price}
                            currency={currency}
                            onToCart={onAddDevice} />
                    ))}
                </div>

            </section>

            <section className="mb-5">
                <div className="d-flex align-items-center mb-5">
                    <h3 className="p-0 m-0">Add-Ons</h3>
                </div>

                <div className="row row-cols-1 row-cols-md-3 g-4 justify-content-start">
                    {
                        Object.keys(productsAddons).map((key, index) => (
                            <ProductCard
                                key={index}
                                id={productsAddons[key].id}
                                type='addon'
                                image={productsAddons[key].image}
                                title={productsAddons[key].title}
                                text={productsAddons[key].text}
                                {...productsAddons[key].meta}
                                disabled={!selectedDevices.length}
                                price={productsAddons[key].price}
                                currency={currency}
                                onToCart={onAddDevice} />
                        ))
                    }
                </div>

            </section>
        </>
    );
}
 
export default StepOne;