
import DeviceIconCPU from '../../assets/spec-icons/processor-blk.svg';
import DeviceIconHDD from '../../assets/spec-icons/harddrive-blk.svg';
import DeviceIconDOS from '../../assets/spec-icons/laptop-blk.svg';
import DeviceIconMEM from '../../assets/spec-icons/memory-blk.svg';
import DeviceIconGFX from '../../assets/spec-icons/videocard-blk.svg';
import DeviceIconPRT from '../../assets/spec-icons/ports-blk.svg';
import { useCountRenders } from '../../hooks/useCountRenders';

const ProductSummary = ({cpu, os, hdd, ram, gfx, ports}) => {

    useCountRenders('Product Summary');
    
    return (
        <div className="card border-0">
            <div className="card-body">
                <div className="px-2 px-md-4 py-4">
                    <ul className="list-group list-group-flush device-spec-list">
                        <li className="list-group-item position-relative ps-5">
                            <div className="device-spec-icons position-absolute">
                                <img src={DeviceIconCPU} />
                            </div>
                            <span>{ cpu }</span>
                        </li>
                        <li className="list-group-item position-relative ps-5">
                            <div className="device-spec-icons position-absolute">
                                <img src={DeviceIconDOS} />
                            </div>
                            <span>{ os }</span>
                        </li>
                        <li className="list-group-item position-relative ps-5">
                            <div className="device-spec-icons position-absolute">
                                <img src={DeviceIconHDD} />
                            </div>
                            <span>{ hdd }</span>
                        </li>
                        <li className="list-group-item position-relative ps-5">
                            <div className="device-spec-icons position-absolute">
                                <img src={DeviceIconMEM} />
                            </div>
                            <span>{ ram }</span>
                        </li>
                        <li className="list-group-item position-relative ps-5">
                            <div className="device-spec-icons position-absolute">
                                <img src={DeviceIconGFX} />
                            </div>
                            <span>{ gfx }</span>
                        </li>
                        <li className="list-group-item position-relative ps-5">
                            <div className="device-spec-icons position-absolute">
                                <img src={DeviceIconPRT} />
                            </div>
                            <span>{ ports }</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
 
export default ProductSummary;