import { useReducer } from "react";


const StepTwo = ({ data, onChange, onStep }) => {

    const [state, setState] = useReducer((state, newState) => ({
        ...state,
        ...newState
    }), {
        ...data
    });

    const onChangeHandler = (target, value) => {
        setState({[target]: value})
        onChange(target, value);
    }
    
    return (
        <section className="">
            <div className="d-flex align-items-center mb-5">
                <button className="btn btn-link hint--top" aria-label="Back" onClick={() => onStep(1)}><i className="mdi mdi-arrow-left mdi-18px"></i></button>
                <div className="btn-divider"></div>
                <h3 className="p-0 m-0 ms-3">Your Information</h3>
            </div>

            <div className="row">
                <div className="col-12 col-md-7">
                    
                    <div className="row row-cols-1 row-cols-md-2 mb-3">
                        <div className="col">
                            <label htmlFor="" className="form-label">First Name <span className="text-danger">*</span></label>
                            <input type="text" name="first_name" className="form-control" value={state.firstName} onChange={(e)=> onChangeHandler('firstName', e.target.value )} autoFocus />
                        </div>
                        <div className="col">
                            <label htmlFor="" className="form-label">Last Name <span className="text-danger">*</span></label>
                            <input type="text" name="last_name" className="form-control" value={state.lastName} onChange={(e) => onChangeHandler('lastName', e.target.value)} />
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Email <span className="text-danger">*</span></label>
                        <input type="text" name="email" className="form-control" value={state.email} onChange={(e) => onChangeHandler('email', e.target.value)} />
                        <small className="text-muted">Valid email address is required.</small>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Phone</label>
                        <input type="text" name="phone" className="form-control" value={state.phone} onChange={(e) => onChangeHandler('phone', e.target.value)} />
                    </div>
                    
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Company</label>
                        <input type="text" name="phone" className="form-control" value={state.company} onChange={(e) => onChangeHandler('company', e.target.value)} />
                    </div>
                </div>

                <div className="col-12 col-md-5">
                        <div className="alert alert-primary bg-white ls-8 d-flex">
                        <i className="mdi mdi-information mdi-24px text-primary me-3"></i>
                        <p className="m-0">
                            <strong className="d-block">Existing Screens Users</strong>
                            Please provide your account email address.
                            <span className="d-block border-top my-2 opacity-15 border-primary"></span>
                            <strong className="d-block">New Users</strong>
                            Please provide the email address you want your account to be created for.
                        </p>
                    </div>
                </div>
            </div>

        </section>
    );
}
 
export default StepTwo;