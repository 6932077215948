import { useReducer } from "react";


const StepThree = ({ data, onChange, onStep, isCheckingOut }) => {

    const [state, setState] = useReducer((state, newState) => ({
        ...state,
        ...newState
    }), {
        ...data
    });

    const onChangeHandler = (target, value) => {
        setState({ [target]: value })
        onChange(target, value);
    }

    return (
        <>
            <section className="">
                <div className="d-flex align-items-center mb-5">
                    <button className={`btn btn-link hint--top ${isCheckingOut ? 'pe-none opacity-50 disable': ''}`} aria-label="Back" onClick={() => onStep(2)}><i className="mdi mdi-arrow-left mdi-18px"></i></button>
                    <div className="btn-divider"></div>
                    <h3 className="p-0 m-0 ms-3">Shipping Address</h3>
                </div>

                <div className="row mb-4 ls-8">
                    <div className="col-12 col-md-8">

                        <div className="mb-3">
                            <label htmlFor="" className="form-label">Address <span className="text-danger">*</span></label>
                            <input type="text" name="address" className="form-control" value={state.address_one} onChange={(e) => onChangeHandler('address_one', e.target.value)} autoFocus />
                        </div>
                        <div className="mb-4">
                            <input type="text" name="address_two" className="form-control" value={state.address_two} onChange={(e) => onChangeHandler('address_two', e.target.value)} />
                        </div>

                        <div className="row row-cols-1 row-cols-md-2 mb-4">
                            <div className="col mb-4 mb-md-0">
                                <label htmlFor="" className="form-label">City <span className="text-danger">*</span></label>
                                <input type="text" name="city" className="form-control" value={state.address_city} onChange={(e) => onChangeHandler('address_city', e.target.value)} />
                            </div>
                            <div className="col">
                                <label htmlFor="" className="form-label">Postcode <span className="text-danger">*</span></label>
                                <input type="text" name="postcode" className="form-control" value={state.address_postcode} onChange={(e) => onChangeHandler('address_postcode', e.target.value)} />
                            </div>
                        </div>

                        <div className="row row-cols-1 row-cols-md-2 mb-4">
                            <div className="col mb-4 mb-md-0">
                                <label htmlFor="" className="form-label">State <span className="text-danger">*</span></label>
                                <select name="state" className="form-select" value={state.address_state} onChange={(e) => onChangeHandler('address_state', e.target.value)}>
                                    <option value="Johor">Johor</option>
                                    <option value="Kedah">Kedah</option>
                                    <option value="Kelantan">Kelantan</option>
                                    <option value="Kuala Lumpur">Kuala Lumpur</option>
                                    <option value="Labuan">Labuan</option>
                                    <option value="Melaka">Melaka</option>
                                    <option value="Negeri Sembilan">Negeri Sembilan</option>
                                    <option value="Pahang">Pahang</option>
                                    <option value="Penang">Penang</option>
                                    <option value="Perak">Perak</option>
                                    <option value="Perlis">Perlis</option>
                                    <option value="Putrajaya">Putrajaya</option>
                                    <option value="Sabah">Sabah</option>
                                    <option value="Sarawak">Sarawak</option>
                                    <option value="Selangor">Selangor</option>
                                    <option value="Terengganu">Terengganu</option>
                                </select>
                            </div>
                            <div className="col">
                                <label htmlFor="" className="form-label">Country</label>
                                <input type="text" name="state" className="form-control" value={'Malaysia'} readOnly />
                            </div>

                        </div>

                    </div>
                </div>


                <div className="form-check form-switch mb-5">
                    <input className="form-check-input" checked={state.isSameAddress} type="checkbox" id="flexShippingSame" onChange={(e) => onChangeHandler('isSameAddress', e.target.checked)} />
                    <label className="form-check-label" htmlFor="flexShippingSame">Billing address same as shipping address.</label>
                </div>


                <div className={`${state.isSameAddress ? 'd-none' : 'd-block'}`}>
                    <hr className="opacity-15" />
                    <div className="d-flex align-items-center mb-5 mt-4">
                        <h3 className="p-0 m-0">Billing Address</h3>
                    </div>

                    <div className="row mb-4 ls-8">
                        <div className="col-12 col-md-8">

                            <div className="mb-3">
                                <label htmlFor="" className="form-label">Address <span className="text-danger">*</span></label>
                                <input type="text" name="address" className="form-control" value={state.billing_one} onChange={(e) => onChangeHandler('billing_one', e.target.value)} />
                            </div>
                            <div className="mb-4">
                                <input type="text" name="billing_two" className="form-control" value={state.billing_two} onChange={(e) => onChangeHandler('billing_two', e.target.value)} />
                            </div>

                            <div className="row row-cols-1 row-cols-md-2 mb-4">
                                <div className="col mb-4 mb-md-0">
                                    <label htmlFor="" className="form-label">City <span className="text-danger">*</span></label>
                                    <input type="text" name="city" className="form-control" value={state.billing_city} onChange={(e) => onChangeHandler('billing_city', e.target.value)} />
                                </div>
                                <div className="col">
                                    <label htmlFor="" className="form-label">Postcode <span className="text-danger">*</span></label>
                                    <input type="text" name="state" className="form-control" value={state.billing_postcode} onChange={(e) => onChangeHandler('billing_postcode', e.target.value)} />
                                </div>
                            </div>

                            <div className="row row-cols-1 row-cols-md-2 mb-4">
                                <div className="col mb-4 mb-md-0">
                                    <label htmlFor="" className="form-label">State <span className="text-danger">*</span></label>
                                    <select name="state" className="form-select" value={state.billing_state} onChange={(e) => onChangeHandler('billing_state', e.target.value)}>
                                        <option value="Johor">Johor</option>
                                        <option value="Kedah">Kedah</option>
                                        <option value="Kelantan">Kelantan</option>
                                        <option value="Kuala Lumpur">Kuala Lumpur</option>
                                        <option value="Labuan">Labuan</option>
                                        <option value="Melaka">Melaka</option>
                                        <option value="Negeri Sembilan">Negeri Sembilan</option>
                                        <option value="Pahang">Pahang</option>
                                        <option value="Penang">Penang</option>
                                        <option value="Perak">Perak</option>
                                        <option value="Perlis">Perlis</option>
                                        <option value="Putrajaya">Putrajaya</option>
                                        <option value="Sabah">Sabah</option>
                                        <option value="Sarawak">Sarawak</option>
                                        <option value="Selangor">Selangor</option>
                                        <option value="Terengganu">Terengganu</option>
                                    </select>
                                </div>
                                <div className="col">
                                    <label htmlFor="" className="form-label">Country</label>
                                    <input type="text" name="state" className="form-control" value={'Malaysia'} readOnly />
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

            </section>
        </>
    );
}
 
export default StepThree;