import { Link } from "react-router-dom";


const Terms = () => {
    return (
        <div className="terms-text mb-3">

            <p><strong>The following terms have the following meanings in this Terms of Use:</strong></p>
            <ul>
                <li>Business Day means any day other than, Saturdays, Sundays or public holidays gazetted for Selangor, Malaysia.</li>
                <li>Content(s) means certain information including but not limited to text, sounds, music, photographs, videos, data, graphics, images, logos, button icons, audio clips, messages, links, listings or other materials placed on the Services.</li>
                <li>Intellectual Property means copyright and related rights, brand, trade name, logos, registered and unregistered trademarks, service marks, other proprietary identifying marks, patent, know how, confidential information, trade secrets, industrial design, registered and unregistered design, utility model, domain names, inventions, processes and including other intellectual property.</li>
                <li>Intellectual Property Rights means rights to inventions; right to sue for passing off; all Intellectual Property; rights; benefits; title; proprietary rights; interest in licenses; permits; and any other intellectual property rights; for any of the foregoing anywhere in the world.</li>
                <li>Ounch refers to Ounch Sdn Bhd (988628-W) and/or its related corporations, and includes their successors, assigns, employees and agents.</li>
                <li>Services means the digital signage and other content aggregation services made available through the website.</li>
                <li>Related Corporations means the related corporations as defined under the Companies Act, 1965.</li>
                <li>Terms of Use refers to these terms and conditions which govern the relationship between you and Ounch for the use of the Services to which you are granted permission to access.</li>
                <li>You and Your refers to the person or entity accessing and using the Services.</li>
            </ul>
            <ol>
                <li><strong>Access and use</strong>
			        <ol>
                        <li>By viewing, accessing and using the Services, you agree to be bound by this Terms of use.</li>
                        <li>We are entitled, from time to time, to vary, add to or otherwise amend this Terms of Use or any part thereof without any liability. Please visit this Website periodically to determine any changes. The prevailing Terms of Use will be updated on our Website, which will apply and supersede all previous versions. Continued use of the Services following any changes constitutes an acceptance of those changes. If you do not accept this Terms of Use (including any changes), please discontinue your access to the Services.</li>
                    </ol>
                </li>

                <li><strong>Your Use of the Services</strong>
			        <ol>
                        <li>You are solely responsible for all acts or omissions that occur when using the Services. You are solely responsible for the content which you post or transmit through the Online Services and you acknowledge that Ounch are only the conduit for posting, distribution and transmission of data. You agree that:
					        <ol>
                                <li>your use of the Services will only be for lawful purposes and not for any purpose against public interest, public order or national harmony and is in accordance with this Terms of Use;</li>
                                <li>all Content, products or services transmitted or made available via the Services, are the sole responsibility of the person from which such Content, products or services originated;</li>
                                <li>you are solely responsible for all Content, products or services you transmit or make available via the Services or on any website;</li>
                                <li>by using the Services, you may be exposed to third party Content, products or services that may be defamatory, offensive, indecent, objectionable or illegal;</li>
                                <li>you will not use the Services to publish defamatory, infringing, obscene or other unlawful material and post or transmit unlawful, harassing, libellous, abusive, threatening, harmful, vulgar, obscene, privacy invading, sexual/racially offensive or otherwise objectionable material of any kind or nature;</li>
                                <li>you will not use the Services to interfere with, damage, disrupt or unlawfully use or gain access to any service, equipment or computer network without due authorisation;</li>
                                <li>you will not use the Services in a manner that results in distribution of viruses, trojan horses, worms, time bombs, cancelbots, or other similar harmful or damaging programming routines which are designed to interrupt, destroy or limit the functionality of any computer software or hardware or network equipment;</li>
                                <li>you will notify Ounch immediately of any unauthorised use or any other breach of security.</li>
                                <li>You will not engage in any activity, whether lawful or unlawful, which we solely determine, to be harmful to our customers, operations, reputation, good will or customers relation.</li>
                                <li>You will not disrupt or undermine the security of the various networks and systems that are connected to the Services, or violate the regulations, policies, or procedures of such networks or systems. This may include any failure to update software use on your account or website that is known to be vulnerable to malicious activity or exploitation.</li>
                                <li>to make or attempt any illegal or unauthorised access to any part or component of the Services, the network or any third party equipment, accounts, systems or networks to which you can connect through the Services directly or otherwise; or to carry out or attempt any activity that might be used as a precursor to an attempted system penetration (i.e. port, stealth, security or penetration scans or other information gathering activity) on Ounch’s or its service providers’ servers or network;</li>
                                <li>You will not use the Services for any fraudulent, illegal or improper purposes or activities or to violate anybody’s rights or in any way which may affect other users’ enjoyment of or access to the Services or cause annoyance, harassment, irritation, inconvenience or anxiety to anyone, including denial of service attacks;</li>
                                <li>The Service involves access to, use of and exchange of information over the internet and you acknowledge that such access to, use of and exchange of information may not be secure. Ounch will not be liable to you if you suffer loss or damage as a result of the information not being secure.</li>
                                <li>You will comply with all applicable laws, rules and regulations and any instructions, notices, directions, requirements or restrictions which we may impose on the use of the Services;</li>
                            </ol>
                        </li>
                        <li>You are required to provide accurate and complete information to us and inform us immediately of any changes in any particulars or information given to us, including but not limited to any changes in address and/or contact particulars;</li>
                        <li>You hereby grant Ounch a worldwide, royalty free, license to, copy, modify, display, use or transmit, Content in connection with Ounch’s performance or enforcement;</li>
                        <li>You hereby release Ounch from any liability, disputes, claims, demands and/or damages of any kind arising from or in any way connected to the use of the Services.
					</li>
				</ol>
			</li>
                    <li><strong>Ounch’s Rights</strong>
				<ol>
                            <li>Ounch may refuse, remove, modify or deny access to any Content, products or services which you transmit or make available via the Services or on any website, including without limitation such Content, products or services that may be or are alleged to be defamatory, offensive, indecent, objectionable or illegal or may have infringed any party’s intellectual property rights. We shall not be responsible for determining the validity of any allegations in this regard.</li>
                            <li>Ounch is not responsible for the condition or Content of any third party sites which may be found on the Services. The link(s) are provided solely for your convenience and do not indicate, expressly or impliedly, any endorsement, authorization or sponsorship by Ounch of those site(s) or the products or services provided there. You access those sites and use their products and services solely at your own risk. Ounch is not responsible for use or any transactions performed on those sites.</li>
                            <li>Ounch may deny access to or remove any third party Content, products or services that may be defamatory, offensive, indecent, objectionable or illegal or may have infringed any party’s intellectual property rights, without notice to you.</li>
                            <li>Without prejudice to paragraph 3.3 above, Ounch may refuse, remove, modify, deny access to, suspend or terminate any third party Content, products or services which you may access, use or acquire via the websites, Services. Ounch will not be liable to you or any third party under this Clause 3.</li>
                            <li>Ounch does not guarantee that you will have access to the Services at all times. You may experience interruptions to the Services and Ounch will not be liable to you if you suffer loss or damage as a result of these interruptions. You access those Content and use their products and services solely at your own risk. In no event will Ounch be liable to you or anyone for any damage arising from or occasioned by the use of these third party Content.</li>
                            <li>If you post Content or submit material, unless we indicate otherwise, you grant Ounch a non-exclusive, royalty free, perpetual, irrevocable, fully sub-licensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media. You grant Ounch and sub-licensees the right to use the name that you submit in connection with such Content.</li>
                            <li>You represent and warrant that you own or otherwise control all the rights to the Content that you post; that the Content is accurate; that the use of the Content you supply does not violate the Ounch Fair Usage Policy and will not cause injury to any person or entity.</li>
                            <li>Ounch may establish general practices and limits concerning use of these Services, including without limitation, the maximum number of times (and the maximum duration for which) you may access the Services, in a given period of time.</li>
                            <li>Ounch reserves the right to investigate potential abuses and/or any abuse pursuant to your use of the Services. You are required to fully cooperate with any of Ounch’s investigations in respect of these matters.</li>
                            <li>Any violation of the Terms of Use warrants immediate termination of your use of the Services.
						</li>
					</ol>
				</li>
                        <li><strong>Copyright and Trademark Notice</strong>
					<ol>
                                <li>The Content in the Services are protected by copyright, trademarks and/or other intellectual property rights and other relevant laws of Malaysia. You may view the Services and use its Content for personal and non-commercial purposes. Any Content on the Services may not be reproduced, distributed, transmitted, published, displayed, broadcasted, stored, adapted, licensed, altered, hyperlinked or otherwise used in any manner or by any means without Ounch’s prior written consent. You may not, without Ounch’s prior written consent, insert a hyperlink to the Services (or any part thereof) on any other website.</li>
                                <li>You may not frame or utilise framing techniques to enclose any Content, trademark, logo, or other proprietary information (including images, text, page, layout, or form) of Ounch without express consent.&nbsp;</li>
                                <li>No license or rights are granted to you and your access or use of the Services should not be construed as granting, by implication, estoppel or otherwise, any license or right to use the trademarks, trade names, logos, or service marks without Ounch’s prior written consent.</li>
                                <li>The compilation (including but not limited to the collection, arrangement, and assembly) of all Content on the Services is the exclusive property of Ounch and protected by copyright. All software used on the Services is the property of Ounch or its software suppliers and protected by copyright.
							</li>
						</ol>
					</li>
                            <li><strong>Personal Information</strong>
						<ol>
                                    <li>You confirm that you have read and agree with the <Link to="privacy">Screen’s Privacy Statement</Link> set out in Screens’ website.
								</li>
							</ol>
						</li>
                                <li><strong>Breach of Term, Termination and/or Suspension</strong>
							<ol>
                                        <li>Ounch reserves the rights to restrict, suspend or terminate your account or your access to the Services.</li>
                                        <li>Nothing contained in this Terms of Use shall be construed to limit our actions or remedies in any matter and we reserve at all times all rights and remedies available to us at law.</li>
                                        <li>Ounch shall not be liable for any failure to perform its obligations herein caused by acts of God, insurrection, war, act of terrorism, national or local emergencies, requirements of government or other competent authorities, industrial disputes of any kind, fire, lightning, explosions, flood, inclement weather, subsidence, acts or omissions of third party, third party suppliers, operators, service providers, contractors or agents whom we may use to perform any part of the Service(s), computer software malfunction, electrical power failure and/or interruption or disruption of network or any cause outside Ounch’s reasonable control.</li>
                                        <li>Ounch reserves the right to modify for any reason whatsoever or suspend or terminate (temporarily or permanently) the Services at any time and from time to time with or without notice. You agree that Ounch shall not be liable to you or to any other party for such modification, suspension or termination of the Services.</li>
                                        <li>Any breach or attempted breach to compromise Ounch’s and/or any other party’s network security is prohibited and may result in criminal and/or civil liability.
									</li>
								</ol>
							</li>
                                    <li><strong>Password Confidentiality</strong>
								<ol>
                                            <li>We are committed to safeguarding your privacy online. We have taken steps to ensure that reasonable measures have been put in place to prevent unauthorised access, disclosures, loss or thefts of personally identifiable information collected and transmitted through the Services.</li>
                                            <li>You may be required to register and provide personal details in order to access certain Online Services. In such circumstances, you may be required to enter your password every time you access the Online Services. You are responsible for maintaining the confidentiality of your password and/or user authentication and for restricting access to your computer, and you agree to accept responsibility and liabilities for all activities that occur under your password and/or user authentication, whether authorised by you or not. You undertake to observe all security measures prescribed by Ounch concerning your password or generally in respect of the access and use of the Services. Should you have any reason to believe that your password has been misused and/or compromised whether by theft, fraud or otherwise, you shall inform Ounch immediately after becoming aware of the same and you will proceed to change your password.</li>
                                            <li>Ounch shall not be liable for any loss or damage which you and/or any third party suffers or may suffer as a result of instructions or transactions transmitted via the Services after successful usage of your password whether or not such usage was authorized by you.</li>
                                            <li>You shall keep Ounch fully indemnified against all loss or damage which Ounch suffers or may suffer in connection, whether directly or indirectly, with usage of your password whether by you or any third party, authorized or otherwise.</li>
                                            <li>Ounch reserves the right to invalidate or suspend your password at any time without being obliged to offer you any explanation or prior notice. Ounch shall not be liable for any loss or damage which you may suffer as a result of such invalidation and/or suspension.</li>
                                            <li>Ounch reserves the right to discontinue the password accessing system with respect to the Services at any time, without prior notice. Ounch shall not be liable for any loss or damage which you may suffer as a result of such discontinuation.
										</li>
									</ol>
								</li>
                                        <li><strong>Liability</strong>
									<ol>
                                                <li>Notwithstanding anything to the contrary herein contained, Ounch and our respective personnel and/or suppliers will not be liable to you or any other party in contract, tort (including negligence ) or otherwise in respect of any claim brought by you or by any third party for any loss of profit or revenue or loss of business or loss of data or for interrupted or suspended communications or for any direct, indirect, special, incidental, consequential damages, punitive damages, or for any injury caused or suffered by a person or damage to property or any damages arising out of or in connection with the Services, whether or not Ounch, our personnel or suppliers were or should have been aware of the possibility that such damage or loss would occur. The exclusion referred to herein applies to any action giving rise to an obligation, duty or liability. We disclaim any and all liability to the fullest extent allowed by law.</li>
                                                <li>By accessing and using the Services, you agree to waive and hold Ounch and our respective personnel and/or suppliers harmless from any claims relating to any action taken by Ounch and our respective personnel and/or suppliers including the conduct of an investigation, issuance of a warning, refusal, removal, modification or denial of access to Content, products or services, suspending or terminating the Services, or other appropriate action in relation to any suspected or alleged breach of this Terms of Use.</li>
                                                <li>Without prejudice to the foregoing, where a court or an arbitrator holds or finds Ounch and our respective personnel and/or suppliers liable to you for any breach or default, you agree that the amount of damages payable by Ounch and our respective personnel and/or suppliers to you will not at any time exceed the sum of RM300.00.
											</li>
										</ol>
									</li>
                                            <li><strong>Disclaimer</strong>
										<ol>
                                                    <li>The Services (including the information, names, images, pictures, logos and icons regarding or relating to Ounch, its products and services or to third party products and services (if any)) is provided on an “as is” and “as available” basis and Ounch makes no express or implied representation or warranties in relation to the Services, any property or information in or any transaction performed through the Services including but not limited to:
												<ol>
                                                            <li>all warranties of safety, legality, merchantability, satisfaction, fitness for purpose, title, non-infringement and compatibility of the Services;</li>
                                                            <li>the security, availability, accessibility, timeliness, error-free and uninterrupted use of the Services;</li>
                                                            <li>the truth and accuracy of the Content or subsequent modifications thereto;</li>
                                                            <li>the actions and omissions of users whether occurred on or outside the Services;</li>
                                                            <li>any deletion, non-delivery or failure to store any user communications or personalised settings;</li>
                                                            <li>the inability to use the Services or inability to receive any messages received or transactions entered through the Services;</li>
                                                            <li>the accuracy and reliability of results obtained from the use of the Services and that any errors in the software will be corrected;</li>
                                                            <li>any implied warranty arising from the course of dealing or trade; and</li>
                                                            <li>any obligation, liability, right, claim in tort or otherwise whether or not arising from the negligence of Ounch.</li>
                                                        </ol>
                                                    </li>
                                                    <li>To the full extent permitted by law, Ounch disclaim any and all such warranties.</li>
                                                    <li>In no event shall Ounch, their respective agents and representatives, suppliers, vendors or merchants who have a presence on the Services be liable to you or anyone else for any loss or injury or any direct, indirect, special, exemplary, consequential damages, or tortuous action, arising out of or in connection with your access, use of, or your inability to access or use the Services.
												</li>
											</ol>
										</li>
                                                <li><strong>Indemnity</strong>
											<ol>
                                                        <li>You agree to indemnify Ounch, our affiliates, service providers, employees, directors and agents in full against all claims, damages, losses, liabilities, expenses, demands, actions, penalties and costs, arising out of or in connection with your access or use of the Services, your negligence, or any omission, act or breach of this Terms of Use by you or any other persons using your account (whether or not expressly authorised by you).
													</li>
												</ol>
											</li>
                                                    <li><strong>Assignment</strong>
												<ol>
                                                            <li>You are not permitted to assign or novate any, or any part, of your rights and/or obligations under the Terms of Use to any party, without our prior written consent.</li>
                                                            <li>You agree and consent that we may assign any, or any part, or all, of our rights under the Terms of Use to our Related Corporations or any third party by notice in writing to you.</li>
                                                            <li>You further agree and consent that we may novate any, or any part, or all, of our rights and/or obligations under the Terms of Use, or the Terms of Use itself, to our Related Corporations or any third party by notice in writing to you. Your continued usage, after service of such notice on you, of the Services will be deemed as your consent to continue with the Services after such novation.
														</li>
													</ol>
												</li>
                                                        <li><strong>Notices</strong>
													<ol>
                                                                <li>Any notices or consents to be given to you may be given in such manner as Ounch deems fit (including any electronic means) and will be deemed received if hand delivered or posted to your last known address or faxed to your last known fax number or sent to your electronic mail address. Any notices which you give to Ounch must be sent in writing to #06-01 Wisma Minlon, Bt 12 Lebuhraya Sg Besi,&nbsp; 43300 Seri Kembangan,Selangor or to any other address as notified by us from time to time. Any notice to be given according to this Terms of Use will be deemed to be given:-
															<ol>
                                                                        <li>if sent by pre-paid registered post, on the second business day after the date of posting;</li>
                                                                        <li>if by ordinary post, on the fifth business day after the date of posting;</li>
                                                                        <li>if hand delivered, upon delivery;</li>
                                                                        <li>if sent by fax, upon notification that the message was successfully transmitted in its entirety; and if sent by email, on the date the email was recorded as sent in the e-mail.</li>
                                                                    </ol>
                                                                </li>
                                                            </ol>
                                                        </li>
                                                        <li><strong>Governing Law</strong>
													<ol>
                                                                <li>By accessing and using the Services, you agree that such access and/or use, as well as this Terms of Use shall be governed by and construed in accordance with the laws of Malaysia and you agree to submit to the exclusive jurisdiction of the Malaysian courts.
															</li>
														</ol>
													</li>
                                                            <li><strong>General Terms</strong>
														<ol>
                                                                    <li>The use of the Services is subject to this Terms of Use and such other terms and conditions as may be agreed or accepted by you (collectively referred to as “Ounch’s Agreement”). If there is any conflict or inconsistency between the Ounch’s Agreement and this Terms of Use, such conflict or inconsistency will be resolved in a manner most favourable to Ounch. However, if such conflict or inconsistency cannot be so resolved, the Ounch’s Agreement will prevail over this.</li>
                                                                    <li>This Terms of Use shall have effect only to the extent not forbidden by law. For avoidance of doubt, it is agreed and declared in particular, without limitation, that nothing herein shall be construed as an attempt to contract out of any provisions of the Consumer Protection Act 1999, if and where the said Act is applicable.</li>
                                                                    <li>No delay or indulgence by Ounch in enforcing any terms or conditions of this Terms of Use nor the granting of time by Ounch to you shall prejudice the rights or powers of Ounch nor shall any waiver by Ounch of any breach constitute a continuing waiver in respect of any subsequent or continuing breach.</li>
                                                                    <li>If any part of the Terms of Use should be determined to be illegal, invalid or otherwise unenforceable by reason of the laws of any state or country in which this Terms of Use are intended to be effective, then to the extent and within the jurisdiction which that term or condition is illegal, invalid or unenforceable, it shall be severed and deleted from the Terms of Use and the remaining Terms of Use here of shall survive, remain in full force and effect and continue to be binding and enforceable. The Services may be accessed throughout Malaysia and overseas. Ounch makes no representation that the content of this Services complies with the laws (including intellectual property laws) of any country outside Malaysia. If you access the Services from outside Malaysia, you do so at your own risk and are responsible for ensuring compliance with all laws in the place where you are located.</li>
                                                                </ol>
                                                            </li>
												</ol>
                                                        <ul>
                                                            <li><strong>You must also</strong>
                                                                    <ul>
                                                                        <li>cease to utilize the Services or any part thereof for such period as may be required by Ounch.</li>
                                                                        <li>not use the Services to cause embarrassment, distress, annoyance, irritation or nuisance to any person.</li>
                                                                        <li>comply with all notices or directions relating to your use of the Services as we may see fit to issue from time to time or if we have reason or cause to suspect that you are not complying with your responsibilities and obligations under this Terms of Use.</li>
                                                                        <li>at all times keep the personal identification and password created confidential and not release the password to any person.</li>
                                                                    </ul>
														</li>
													</ul>
                                                    
            <p className="my-5">- END -</p>
												</div>
    );
}
 
export default Terms;