

const PageDescription = ({ title, children }) => {
    return (
        <section className="section py-5">

            <div className="container bg-overlay-content" style={{ marginTop: '-100px' }} data-aos="fade-up" data-aos-delay="100">

                <div className="bg-white rounded box-shadow py-5 py-md-6 px-4 px-md-0">

                    <div className="row align-items-center py-4 py-md-0">
                        <div className="col-xl-6 col-lg-5 col-md-4 text-center">
                            <h2 className="pb-3 pb-md-0 px-4 px-md-5">{title}</h2>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-7 text-center text-md-start">
                            { children }
                        </div>
                    </div>

                </div>

            </div>

        </section>
    );
}
 
export default PageDescription;