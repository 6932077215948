import { Link } from "react-router-dom";
import Slider from "react-slick";
import SolutionsList from "../../services/SolutionsList";

import Curve from '../Curve';
import './index.scss';


const SolutionsSection = () => {
    return (
        <section className="section py-5">
            <Curve position="slope-right" className="color-secondary" />

            <div className="container pt-6 pb-4 text-center" style={{position:'relative', zIndex: 2}}>
                <h2 className="mb-3">Screens for Every Business</h2>
                <p className="text-muted mb-5 lead">We are the Digital Signage Specialists. <br />Retail, Education, Healthcare or Coporate - We have a solution for you.</p>

                <div className="row">
                    <div className="col">
                        <div className="bg-white box-shadow-lg rounded-3 p-4 pb-5 mb-3">
                            <Slider {...sliderSettings}>
                            {
                                SolutionsList.map((item, index) => (
                                    <div className="img-slide p-3 text-center" key={index}>
                                        <img height="50" src={item.image} alt="" className="img-fluid img-thumbnail rounded" />
                                        <Link to={item.link} className="text-reset">
                                            <h4 className="m-0 py-3">{item.caption}</h4>
                                        </Link>
                                    </div>
                                ))
                            }
                            </Slider>                            
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default SolutionsSection;


const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    // autoplay: true,
    // speed: 2000,
    // autoplaySpeed: 2000,
    // cssEase: "linear",
    arrows: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ],
    afterChange: () => {
        if (window.gtag) {
            window.gtag('event', 'solution_slider_swipe', {
                'event_category': 'solution_slider_swipe',
                'event_label': 'Solution Slider Swipe',
                'non_interaction': true
            });
        }
    },
};


