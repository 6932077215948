import DeviceImageFront from '../assets/devices/NUC8CCHKR_Front.png';
import DeviceImageBack from '../assets/devices/NUC8CCHKR_Back.png';
import DeviceKiosk43 from '../assets/devices/Kiosk-Portrait-43.png';
import DeviceKiosk49 from '../assets/devices/Kiosk-Portrait-49.png';
import ProductSpecList from '../components/Common/ProductSpecList';
import ThreeSixityView from '../components/ThreeSixityView';
import { GetPublicSource } from '../components/Common/Helpers';


const ProductDetails = (deviceId) => {
    return new Promise((resolve) => {
        resolve(DeviceDetails[deviceId]);
    });
}

export default ProductDetails;


const DeviceDetails = {
    
    "nuc-rugged" : {

        headerTitle: "Intel® NUC 8 Rugged",
    
        headerText: "Meet the mighty, tiny mini PC only Intel® could build. Get your Intel® NUC exactly the way you need it.",
    
        headerImage: <img src={DeviceImageFront} className="img-fluid d-block mx-auto" />,

        summaryImage: <img src={DeviceImageBack} className="img-fluid d-block mx-auto" />,

        deviceSummary: {
            cpu: 'Intel® Celeron® processor N3350',
            os: 'ScreenplifyOS®',
            hdd: '64 GB eMMC soldered- down',
            ram: '4 GB RAM, dual - channel(soldered - down)',
            gfx: 'Intel® HD Graphics 500, Dual HDMI * (1x HDMI * 2.0a, 1x HDMI * 1.4)',
            ports: '5x USB, Intel® i211 - AT 10 / 100 / 1000 Mbps'
        },

        featureSummary: <>
                <p className="lead mb-4">Dual HDMI*, Qualified for 24x7 operation; delayed AC start; DC overvoltage protection, DC cable strain relief, Internal 4-lane eDP connector, Front panel expansion bay, 3-year warranty.</p>
                <p className="mb-4">Intel® NUC 8 Rugged is a PC kit that’s small on size but not on toughness or reliability. It features a fanless, ventless design to minimize particle intrusion, so it’s built to survive environments most PCs can’t—from a busy factory floor to a dusty warehouse. And even without a fan, it can operate in temperatures from 0-40˚C to thrive in severe in-the-field conditions.</p>
                <p className="mb-4">Housed in a small chassis that’s slim enough to fit almost anywhere, the NUC 8 Rugged is also available as a board-only option that can be easily integrated into your own digital solutions. It’s powered by an Intel® Celeron® processor, making it the ideal PC solution to keep edge analytics, digital signage, or surveillance cameras up and running around the clock. And with a 3-year warranty, it’s performance that’s designed to last.</p>

                <div className="mb-4">
                    <ProductSpecList list={[
                        { text: "Intel® Celeron® processor" },
                        { text: "Fanless and ventless design" },
                        { text: "Minimal dust intrusion" },
                        { text: "Qualified for 24x7 operation" },
                        { text: "Multiple mounting options" },
                        { text: "Dual HDMI*" },
                        { text: "2 USB 3.0 ports" },
                        { text: "2 USB 2.0 ports" },
                        { text: "Internal 4-lane eDP connector" },
                        { text: "Intel® Wireless-AC" },
                        { text: "Bluetooth® technology" },
                        { text: "Intel® Gigabit LAN" },
                        { text: "3-Year limited warranty" }
                    ]} />
                </div>
            </>,

        usageSummary: <>
                <p className="lead mb-4">The Intel® NUC 8 Rugged is built to last and qualified for 24x7 sustained operations—a critical feature for most IoT and commercial operations.</p>
            </>,
        
        techSpecSummary: <>
                <h5 className="mb-2">Dimensions</h5>
                <div className="row no-gutters row-cols-1 mb-4">
                    <div className="col">
                        <img src={ GetPublicSource('/assets/device-installation/nuc_rugged_size.jpg') } alt="" className="img-fluid" />
                    </div>
                </div>

                <h5 className="mb-2">Power Input</h5>
                <p className="mb-5">AC 110 ~ 240V, 50 / 60Hz</p>

                <h5 className="mb-2">Operating Environment</h5>
                <p className="mb-5">0° C to +40 C <br />Storage Temperature: -20° C to +40° C</p>

                <h5 className="mb-2">Mounting Options</h5>
                <p className="mb-5">VESA mounting holes, picture frame keyholes, zip-tie indentations, 3rd party DIN rail bracket, Kensington lock with base security</p>
            </>,
        
        installation: <>
                <h5 className="mb-4">Requirements</h5>
                <ProductSpecList list={[
                    { text: "Full HD TV / Screen with a HDMI Port" },
                    { text: "Power socket for the Device and the TV / Screen" },
                    { text: "Network Connection ( Wifi or Ethernet )" },
                ]} />

                <div className="how-to-steps mt-5" data-no="1">
                    <h5 className="mb-2">Connect HDMI</h5>
                    <p className="text-muted">Connect the HDMI cable with the NUC and the TV / Screen.</p>
                    <div className="row no-gutters row-cols-1 row-cols-md-2">
                        <div className="col">
                            <img src={ GetPublicSource('/assets/device-installation/nuc-rugged-hdmi.jpg') } alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>

                <div className="how-to-steps mb-5" data-no="2">
                    <h5 className="mb-2">Change TV Source/Input</h5>
                    <p className="text-muted">Press the Input or Source button on your TV's remote to change to the correct input.</p>
                </div>

                <div className="how-to-steps" data-no="3">
                    <h5 className="mb-2">Connect Device Power</h5>
                    <p className="text-muted">Please follow the sequance in the illustration.</p>
                    <div className="row no-gutters row-cols-1 row-cols-md-2">
                        <div className="col">
                            <img src={ GetPublicSource('/assets/device-installation/nuc-rugged-power-connect.jpg') } alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>

                <div className="how-to-steps" data-no="4">
                    <h5 className="mb-2">Device Power</h5>
                    <p className="text-muted">The device starts automatically when power is applied. If not, press the power button on the device.</p>
                    <div className="row no-gutters row-cols-1 row-cols-md-2">
                        <div className="col">
                            <img src={ GetPublicSource('/assets/device-installation/nuc-rugged-power-on.jpg') } alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </>
    },

    "standee-43": {

        headerTitle: 'Standee 43"',

        headerText: "Functionality matters. Make your signage standout and mobile. Maximum impact! (Non-Interactive)",

        headerImage: <img src={DeviceKiosk43} className="img-fluid d-block mx-auto" />,

        // summaryImage: <div className="mock-tv d-inline-block ratio ratio-16x9" style={{ maxWidth: '454px' }}>
        //     <a data-fancybox="powereditor" className="video-link" href={`${process.env.PUBLIC_URL}/assets/videos/kiosk-43-1080-landscape.mp4') }>
        //         <img className="card-img-top img-fluid" src={ GetPublicSource('/assets/images/kiosk-43-1080-landscape.jpg') }></img>
        //         </a>
        //     </div>,

        summaryImage: <ThreeSixityView
                folder={`${process.env.PUBLIC_URL}/assets/360-kiosk-43/`}
                filename={`kiosk_{index}.jpg?v=${process.env.REACT_APP_VERSION || '0.0.1'}` }
                amount="36"
                magnifier="3"
            />,

        deviceSummary: {
            cpu: 'Intel® Celeron® processor N3350',
            os: 'ScreenplifyOS®',
            hdd: '64 GB eMMC soldered- down',
            ram: '4 GB RAM, dual - channel(soldered - down)',
            gfx: 'Intel® HD Graphics 500, Dual HDMI * (1x HDMI * 2.0a, 1x HDMI * 1.4)',
            ports: '5x USB, Intel® i211 - AT 10 / 100 / 1000 Mbps'
        },

        featureSummary: <>
            <p className="lead mb-4">A Digital Standee is an excellent tool for promoting a new product, raising brand awareness, communicating with employees or students, and engaging almost any audience in your space.</p>
            <p className="mb-4">High quality fabricated case manufactured in steel and aluminium, painted by eco-friendly material. Adopt high transparency tempered glass with 2mm - 8mm thickness, protecting the LCD screen from been damaged or scratched.</p>

            <div className="mb-4">
                <ProductSpecList list={[
                    { text: "Intel® Celeron® processor" },
                    { text: "A-Grade Commercial Panel" },
                    { text: "1080 x 1920 Resolution" },
                    { text: "16 : 9 Aspect Ratio" },
                    { text: "400 nits Brightness" },
                    { text: "1200 : 1 Contrast Ratio" },
                    { text: "178/178 Viewing Angle" },
                    { text: "8ms Response Time" },
                    // { text: "10 Points Capacitive Touch" },
                    { text: ">50,000 hrs Panel Lifetime" }
                ]} />
            </div>
        </>,

        usageSummary: <>
            <p className="lead mb-4">This all-in-one free-standing ePoster featuring a sleek, slim premium design delivers dynamic, eye-catching messaging in high-traffic areas.</p>
        </>,

        techSpecSummary: <>
            <h5 className="mb-2">Dimensions</h5>
            <div className="row no-gutters row-cols-1">
                <div className="col">
                    <img src={ GetPublicSource('/assets/device-installation/43_kiosk_dimesions.jpg') } alt="" className="img-fluid" />
                </div>
            </div>

            <h5 className="mb-2">Power Input</h5>
            <p className="mb-5">AC 110 ~ 240V, 50 / 60Hz</p>

            <h5 className="mb-2">Environment</h5>
            <p className="mb-5">
                0 C to 55 C Operating temperature <br />
                -25 C to 85 C Storage temperature <br />
                10% to 90% RH, non-condensing Operating humidity and Storage humidity
            </p>
        </>,

        installation: <>
            <h5 className="mb-4">Requirements</h5>
            <ProductSpecList list={[
                { text: "Power socket for the Kiosk" },
                { text: "Network Connection ( WiFi or Ethernet )" },
            ]} />

            <div className="how-to-steps mt-5" data-no="1">
                <h5 className="mb-2">Connect Power</h5>
                <p className="text-muted">Connect the power cable to the kiosk (1) and to the power source (2).</p>
                <div className="row no-gutters row-cols-1">
                    <div className="col">
                        <img src={ GetPublicSource('/assets/device-installation/kiosk-connect-poweron.jpg') } alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
            
            <div className="how-to-steps mt-5" data-no="2">
                <h5 className="mb-2">Switch On</h5>
                <p className="text-muted">Locate the 'Power On' button and firmly press it (3).</p>
            </div>
        </>

    },

    "standee-49": {

        headerTitle: 'Standee 49"',

        headerText: "Free standing and mobile. Attract eye-balls from a far. Use it wherever and whenever (Non-Interactive).",

        headerImage: <img src={DeviceKiosk49} className="img-fluid d-block mx-auto" />,

        // summaryImage: <div className="mock-tv d-inline-block ratio ratio-16x9" style={{ maxWidth: '454px' }}>
        //         <iframe width="560" height="315" src="https://www.youtube.com/embed/WsaPdPB6uRk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowFullScreen></iframe>
        //     </div>,

        summaryImage: <ThreeSixityView
            folder={`${process.env.PUBLIC_URL}/assets/360-kiosk-43/`}
            filename={`kiosk_{index}.jpg?v=${process.env.REACT_APP_VERSION || '0.0.1'}`}
            amount="36"
            magnifier="3"
        />,

        deviceSummary: {
            cpu: 'Intel® Celeron® processor N3350',
            os: 'ScreenplifyOS®',
            hdd: '64 GB eMMC soldered- down',
            ram: '4 GB RAM, dual - channel(soldered - down)',
            gfx: 'Intel® HD Graphics 500, Dual HDMI * (1x HDMI * 2.0a, 1x HDMI * 1.4)',
            ports: '5x USB, Intel® i211 - AT 10 / 100 / 1000 Mbps'
        },

        featureSummary: <>
            <p className="lead mb-4">A Digital Standee is an excellent tool for promoting a new product, raising brand awareness, communicating with employees or students, and engaging almost any audience in your space..</p>
            <p className="mb-4">High quality fabricated case manufactured in steel and aluminium, painted by eco-friendly material. Adopt high transparency tempered glass with 2mm - 8mm thickness, protecting the LCD screen from been damaged or scratched.</p>

            <div className="mb-4">
                <ProductSpecList list={[
                    { text: "Intel® Celeron® processor" },
                    { text: "A-Grade Commercial Panel" },
                    { text: "1080 x 1920 Resolution" },
                    { text: "16 : 9 Aspect Ratio" },
                    { text: "400 nits Brightness" },
                    { text: "1200 : 1 Contrast Ratio" },
                    { text: "178/178 Viewing Angle" },
                    { text: "8ms Response Time" },
                    // { text: "10 Points Capacitive Touch" },
                    { text: ">50,000 hrs Panel Lifetime" }
                ]} />
            </div>
        </>,

        usageSummary: <>
            <p className="lead mb-4">This all-in-one free-standing ePoster featuring a sleek, slim premium design delivers dynamic, eye-catching messaging in high-traffic areas.</p>
        </>,

        techSpecSummary: <>
            <h5 className="mb-2">Dimensions</h5>
            <div className="row no-gutters row-cols-1">
                <div className="col">
                    <img src={ GetPublicSource('/assets/device-installation/49_kiosk_size.jpg') } alt="" className="img-fluid" />
                </div>
            </div>

            <h5 className="mb-2">Power Input</h5>
            <p className="mb-5">AC 110 ~ 240V, 50 / 60Hz</p>

            <h5 className="mb-2">Environment</h5>
            <p className="mb-5">
                0 C to 55 C Operating temperature <br />
                -25 C to 85 C Storage temperature <br />
                10% to 90% RH, non-condensing Operating humidity and Storage humidity
            </p>
        </>,

        installation: <>
            <h5 className="mb-4">Requirements</h5>
            <ProductSpecList list={[
                { text: "Power socket for the Kiosk" },
                { text: "Network Connection ( Wifi or Ethernet )" },
            ]} />

            <div className="how-to-steps mt-5" data-no="1">
                <h5 className="mb-2">Connect Power</h5>
                <p className="text-muted">Connect the power cable to the kiosk (1) and to the power source (2).</p>
                <div className="row no-gutters row-cols-1">
                    <div className="col">
                        <img src={ GetPublicSource('/assets/device-installation/kiosk-connect-poweron.jpg') } alt="" className="img-fluid" />
                    </div>
                </div>
            </div>

            <div className="how-to-steps mt-5" data-no="2">
                <h5 className="mb-2">Switch On</h5>
                <p className="text-muted">Locate the 'Power On' button and firmly press it (3).</p>
            </div>
        </>

    }

}