import { SupportEmail } from "../../components/Common/Helpers"


const MBG = () => {
    return (
        <div className="terms-text mb-3">
            <p className="lead mb-4">Within 14 days of purchase, if for whatever reason you are not completely satisfied with your Screens solution, let us know by sending an email to <SupportEmail/> and you can arrange to send the full/complete set of the hardware to the Ounch’s registered business address. Once we have received it and assessed that the hardware is in good condition, your total payment to Ounch will be refunded.</p>
            <ul>
                <li>To be eligible for a return, you must be a full-paying customer and complete the registration online.</li>
                <li>If the hardware was not purchased from Ounch, it is not eligible for a return or a refund.</li>
                <li>To make a return request, send an email with your order number and reason for returning to <SupportEmail/>.</li>
                <li>If you are not reachable or do not respond to our attempts to contact you with regards to your return request after the 14 days trial period, you are no longer eligible for a return.&nbsp;&nbsp;</li>
                <li>The 14 days trial is limited to two (2) devices per account holder. and cannot be used to exchange hardware for another model or brand.&nbsp;</li>
                <li>If the hardware has been damaged or not functioning, you will not be granted a return or refund. The returned hardware must be in good condition and functioning.&nbsp;</li>
                <li>The hardware must be wrapped up securely using its original packaging with good protection in order to avoid the product from getting damaged during shipment/ transportation. Failing this, Ounch will not be able to grant the refund if the device is damaged during the transportation/shipment.</li>
                <li>If we have grounds to believe that you are returning the hardware for reasons other than because you’re genuinely dissatisfied with the product we reserve the right to refuse to accept the return, not give you a refund, and reserve the right to refuse to sell our products to you in the future.</li>
                <li>The hardware return shipping charges will be borne by the customer.</li>
                <li>The Customer needs to deal with the party responsible for the shipment if the shipment is delayed/lost/damaged.</li>
                <li>Ounch will not be responsible for the loss or damage to the returned hardware.</li>
            </ul>
        </div>
    );
}
 
export default MBG;