import { GetPublicSource } from "../Common/Helpers";


const HowSection = () => {
    return (
        <section className="section py-5">
            <div className="container pt-6 pb-4 text-center">
                <h2 className="mb-3">How does Screens Work?</h2>
                <p className="text-muted mb-5 lead">3 Easy Steps! Start getting noticed and stop your potential customers right at your door!</p>

                <div className="row align-items-center justify-content-center mt-3 mt-md-0">
                    <div className="col-12 col-md-5 mb-4 mb-d-0">
                        {/* <div className="mock-tv d-inline-block ratio ratio-16x9" style={{maxWidth: '454px'}}>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/_uwUItdOzps?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div> */}
                        <div className="mock-tv d-inline-block ratio ratio-16x9" style={{ maxWidth: '454px' }}>
                            <a data-fancybox="how-screens" className="video-link overflow-hidden" href={`${GetPublicSource('/public/assets/videos/how_screens_work.mp4')}`}>
                                <img className="card-img-top img-fluid" src={`${GetPublicSource('/public/assets/images/how-screens-work-screenshot.JPG')}`}></img>
                            </a>
                        </div>
                    </div>

                    <div className="col-12 col-md-5 text-start mb-5 mb-md-0 align-content-between flex-wrap">
                        <div className="how-to-steps mb-4" data-no="1">
                            <h4 className="mb-2">Choose a Product</h4>
                            <p className="text-muted">Start by choosing the device that best suits to your needs.</p>
                        </div>

                        <div className="how-to-steps mb-4" data-no="2">
                            <h4 className="mb-2">Create Your Content</h4>
                            <p className="text-muted">Use our proven ready-made-templates to create your content in no-time.</p>
                        </div>

                        <div className="how-to-steps mb-4" data-no="3">
                            <h4 className="mb-2">Turn It ON!</h4>
                            <p className="text-muted">That’s it, your journey of turning traffic into customers has just began.</p>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    );
}

export default HowSection;