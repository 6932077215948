import { Link } from "react-router-dom";



const PaymentTerms = () => {
    return (
        <>
            <h4 className="mb-4">Terms of Payment</h4>

            <ul className="list-unstyled">
                <li className="ps-4 mb-2">
                    <i className="mdi mdi-check text-success me-2 ms-n4"></i>All prices inclusive of Software, Cloud Hosting, Maintenance support & West Malaysia delivery.</li>
                <li className="ps-4 mb-2">
                        <i className="mdi mdi-check text-success me-2 ms-n4"></i>All prices subject to 6% SST where applicable.</li>
                <li className="ps-4 mb-2">
                    <i className="mdi mdi-check text-success me-2 ms-n4"></i>All transactions will be made in Malaysian Ringgit.
                </li>
                <li className="ps-4 mb-2">
                    <i className="mdi mdi-check text-success me-2 ms-n4"></i>The user agrees that his/her use of online payment service is entirely at own risk and entirely a responsibility of user.
                </li>
                <li className="ps-4 mb-2">
                    <i className="mdi mdi-check text-success me-2 ms-n4"></i>It is the responsibility of the card holder to obtain a reciept.
                </li>
                <li className="ps-4 mb-2">
                    <i className="mdi mdi-check text-success me-2 ms-n4"></i>Payment should be made to exact bill amount.
                </li>
                <li className="ps-4 mb-2">
                    <i className="mdi mdi-check text-success me-2 ms-n4"></i>Read and understood our <Link to="/legal/terms">terms and conditions.</Link>
                </li>
                <li className="ps-4 mb-2">
                    <i className="mdi mdi-check text-success me-2 ms-n4"></i>Read and understood our <Link to="/legal/returns">warranty and returns.</Link>
                </li>
                <li className="ps-4 mb-2">
                    <i className="mdi mdi-check text-success me-2 ms-n4"></i>Read and understood our <Link to="/legal/shipping">shipping and delivery terms.</Link>
                </li>
            </ul>
        </>
    );
}
 
export default PaymentTerms;