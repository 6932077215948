
import ImageKiosk43 from '../assets/devices/kiosk-43.jpg';
import ImageKiosk49 from '../assets/devices/kiosk-49.jpg';
import ImageNUCRGD from '../assets/devices/nuc-rugged.jpg';
import { isProductionServer } from '../components/Common/Helpers';

const ProductsList = () => {
    return new Promise((resolve) => {
        const list = [

            {
                id: 'standee-43',
                image: ImageKiosk43,
                title: "Standee 43\"",
                text: "All-in-one Free-Standing digital kiosk with a sleek, slim design.",
                meta: {
                    onSale: false,
                    isTopRated: false,
                    isNew: false
                },
                price: {
                    hardware: 4600,
                    software: 1350,
                    isTaxable: true
                },
                stripe: {
                    hardware: {
                        price_id: isProductionServer() ? 'price_1IxZ5KKaaaDtIuDn9TMIYkEY' : 'price_1IxZ8wKaaaDtIuDnrG0vRwZo',
                        tax_id: isProductionServer() ? 'txr_1I8idhKaaaDtIuDnIiO2GZ87' : 'txr_1Hz0vJKaaaDtIuDnljJKWFRj'
                    },
                    software: {
                        price_id: isProductionServer() ? 'price_1J4QsTKaaaDtIuDnNySexagx' : 'price_1Ixr2AKaaaDtIuDnSI5iWT5L',
                        tax_id: isProductionServer() ? 'txr_1I8idIKaaaDtIuDn3xGGM7wT' : 'txr_1HyzGKKaaaDtIuDnPM2zB3wv'
                    }
                }
            },

            {
                id: 'standee-49',
                image: ImageKiosk49,
                title: "Standee 49\"",
                text: "Free standing and mobile. Attract eye-balls from a far. Use it whereever and whenever.",
                meta: {
                    onSale: false,
                    isTopRated: false,
                    isNew: false
                },
                price: {
                    hardware: 5400,
                    software: 1350,
                    isTaxable: true
                },
                stripe: {
                    hardware: {
                        price_id: isProductionServer() ? 'price_1IxZ6yKaaaDtIuDnl29prsT5' : 'price_1IxZ9oKaaaDtIuDnZRg5mkvl',
                        tax_id: isProductionServer() ? 'txr_1I8idhKaaaDtIuDnIiO2GZ87' : 'txr_1Hz0vJKaaaDtIuDnljJKWFRj'
                    },
                    software: {
                        price_id: isProductionServer() ? 'price_1J4QtlKaaaDtIuDnLQ3TI436' : 'price_1Ixr3eKaaaDtIuDnGbhRFCnh',
                        tax_id: isProductionServer() ? 'txr_1I8idIKaaaDtIuDn3xGGM7wT' : 'txr_1HyzGKKaaaDtIuDnPM2zB3wv'
                    }
                }
            },

            {
                id: 'nuc-rugged',
                image: ImageNUCRGD,
                title: "Intel® NUC 8 Rugged",
                text: "Intel® NUC 8 Rugged is a PC kit that’s small on size but not on toughness or reliability.",
                meta: {
                    onSale: false,
                    isTopRated: true,
                    isNew: false
                },
                price: {
                    hardware: 985,
                    software: 1015,
                    isTaxable: true
                },
                stripe: {
                    hardware: {
                        price_id: isProductionServer() ? 'price_1IxZ7fKaaaDtIuDnrWRMVRi4' : 'price_1IxZAJKaaaDtIuDnIHdUjFpG',
                        tax_id: isProductionServer() ? 'txr_1I8idhKaaaDtIuDnIiO2GZ87' : 'txr_1Hz0vJKaaaDtIuDnljJKWFRj'
                    },
                    software: {
                        price_id: isProductionServer() ? 'price_1J4QugKaaaDtIuDn06Z0dNss' : 'price_1Ixr4lKaaaDtIuDnXt2n1LVG',
                        tax_id: isProductionServer() ? 'txr_1I8idIKaaaDtIuDn3xGGM7wT' : 'txr_1HyzGKKaaaDtIuDnPM2zB3wv'
                    }
                }
            }

        ]

        resolve(list);
    });
}


export default ProductsList;


        
        
    