


export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const SupportEmail = () => {
    return (
        <a href="mailto:support@screens.my">support@screens.my</a>
    )
}

export const getSupportEmail = () => {
    return '<a href="mailto:support@screens.my">support@screens.my</a>';
}

export const configNumberSeperator = {
    decimalScale: 2,
    fixedDecimalScale: true,
    displayType: 'text',
    thousandSeparator: true,
    prefix: 'MYR '
}


export function UpdateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
}


export const GetPublicSource = (url) => {
    // Remove public folder
    const str = url.replace("/public", "");
    return `${process.env.PUBLIC_URL}${str}?v=${process.env.REACT_APP_VERSION || '0.0.1'}`
}


export function ValidateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


export const isProductionServer = () => {
    return location.hostname.match('screens.my');
}