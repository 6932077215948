

const SectionBox = ({ title, text, icon }) => {
    return (
        <>
            <div className="svg-icon lg">
                { icon }
            </div>
            <h4 className="mb-3">{ title }</h4>
            <p className="text-muted">{ text }</p>
        </>
    );
}
 
export default SectionBox;