import EverythingSection from "../../components/Everything"
import HowSection from "../../components/How"
import MainHero from "../../components/MainHero"
import FaqSection from "../../components/SectionFAQ"
import ProductsSection from "../../components/SectionProducts"
import SolutionsSection from "../../components/SectionSolutions"
import WhatSection from "../../components/What"


const HomePage = () => {
    return (
        <>
            
            <MainHero/>

            <WhatSection />

            <HowSection />

            <EverythingSection />

            <SolutionsSection />

            <ProductsSection />

            <FaqSection />
             
        </>
    );
}
 
export default HomePage;