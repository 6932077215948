import { Link } from "react-router-dom";
import NumberFormat from 'react-number-format';
import { configNumberSeperator } from "./Helpers";

const ProductCard = (props) => {

    const disabled = props.disabled || false;

    return (
        <div className={`col product-card ${disabled ? 'disabled opacity-50 pe-none' : ''}`}>
            <div className="card h-100 card-hover rounded-3">

                {props.isTopRated && <span className="badge badge-floating rounded-pill bg-warning">Top rated</span>}
                
                {props.onSale && <span className="badge badge-floating rounded-pill bg-danger">Sale</span>}

                {props.isNew && <span className="badge badge-floating rounded-pill bg-success">New</span>}

                <img src={props.image} className="card-img-top" alt={props.title} />
                <div className="card-body pb-0">
                    <h5 className="card-title">{props.title}</h5>
                    <p className="card-text mb-0">{props.text}</p>
                </div>
                <div className="card-footer align-items-center bg-white border-0">
                    <p className="card-text ftw-600">
                        <NumberFormat value={props.price.hardware + props.price.software} {...configNumberSeperator} prefix={`${props.currency} `} />
                    </p>
                    {props.onToCart && <button className="btn btn-link float-end hint--top" aria-label="To Cart" onClick={() => props.onToCart(props)}>
                        <i className="mdi mdi-cart-outline btn-addtocart"></i>
                    </button>}
                    {/* <Link to={props.url} className="btn btn-link float-end hint--top" aria-label="To Cart"><i className="mdi mdi-cart-variant btn-addtocart"></i></Link>*/}
                    {props.url && <Link to={props.url} className="btn btn-link text-muted hint--top" aria-label="Info"><i className="mdi mdi-information-outline mdi-18px"></i></Link>}
                </div>
            </div>
        </div>
    );
}
 
export default ProductCard;