

const Privacy = () => {
    return (
        <div>
            <p>This Privacy Policy discloses the privacy practices for the Screens website (collectively, the “Website” located at https://www.screens.my ). Ounch Sdn Bhd, the provider of the Website (referred to as “Ounch”, “us” or “we”), is committed to protecting your privacy online. Please read the following to learn what information we collect from you (the “User” or the “End User”) and how we use that information. If you have any questions about our privacy policy, please email us.</p>

            <p className="text-uppercase">PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING OUR WEBSITE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREED TO BE BOUND TO ALL THE TERMS OF THIS PRIVACY POLICY AND OUR WEBSITE TERMS OF USE. IF YOU DO NOT AGREE TO THESE TERMS, EXIT THIS PAGE AND DO NOT ACCESS OR USE THE WEBSITE.</p>

            <ul className="list-unstyled">
                <li className="mb-3"><strong>TYPES OF INFORMATION COLLECTED AND USES OF COLLECTED INFORMATION:</strong>
                    <ul className="py-3">
                        <li className="mb-3"><strong>PERSONALLY IDENTIFIABLE INFORMATION</strong> – Personally Identifiable Information is information that pertains to a specific End User. When you engage in certain activities on the Website, such as creating an account, ordering a product or service from us, filling out a survey, sending us or our partners feedback, requesting information about our services, we may ask you to provide certain information about yourself. It is optional for you to engage in an Identification Activity. If you elect to engage in an Identification Activity, however, we may ask you to provide us with certain personal information about yourself, such as your first and last name, mailing address (including zip code), email address, telephone number and date of birth.</li>
                        <li><strong>NON-PERSONALLY IDENTIFIABLE INFORMATION</strong> - Non-Personally Identifiable Information is information that does not identify a specific End User. This type of information may include things like the Uniform Resource Locator (“URL”) of the website you visited before coming to our Website, the URL of the website you visit after leaving our Website, the type of browser you are using and your Internet Protocol (“IP”) address. We use Non-Personally Identifiable Information to troubleshoot, administer the Website, analyze trends, gather demographic information, comply with applicable law, and cooperate with law enforcement activities. We may also use this information to measure the overall effectiveness of our online advertising, content, and programming.</li>
                    </ul>
                </li>

                <li className="mb-3">
                    <strong className="d-block mb-2">RELEASE OF PERSONALLY IDENTIFIABLE INFORMATION</strong>
                    We will not share, sell, rent, or trade your Personally Identifiable Information with other parties except as provided below: We may share your information for our Protection and the Protection of Others. We reserve the right to disclose your personally identifiable information as required by law and when we believe that disclosure is necessary to protect our rights and/or comply with a judicial proceeding, court order, or legal process served on our Website; enforce or apply this Privacy Policy, our Website Terms of Use or other agreements; or protect the rights, property or safety of the Website, its Users or others.
                </li>

                <li className="mb-3">
                    <strong className="d-block mb-2">UPDATING AND CORRECTING INFORMATION</strong>
                    We believe that you should have the ability to access and edit the Personally Identifiable Information you provide us. You may change any of your Personally Identifiable Information by logging into your account and accessing the “Edit Profile” section of the site. If you wish to update information that is not changeable through the website, you can do so by sending us an email at info@screens.my. Please indicate your name, address and email address, and what information you would like to update when you contact us. We encourage you to promptly update your Personally Identifiable Information if it changes. You may ask to have the information on your account deleted or removed. In addition, in may be impossible for us to completely delete all of your information because we periodically backup information.
                </li>

                <li className="mb-3">
                    <strong className="d-block mb-2">USER CHOICES ON COLLECTION AND USE OF INFORMATION</strong>
                    We will send you Administrative and Promotional emails. We will send you information regarding your account activity and purchases as well as updates about our products and promotional offers. “Administrative Emails” relate to a User’s activity on the Website, and include emails regarding a particular User’s account, requests or inquiries, and purchases of products and services. Promotional Emails advertise our products and services. If you do not want to receive Promotional Emails from us, you may elect to opt-out of receiving Promotional Emails at any time after registering by e-mailing us at info@screens.my. When contacting us, please indicate your name, address, email address, and what Promotional Emails you do not want to receive.
                </li>
                
                <li className="mb-3">
                    <strong className="d-block mb-2">SECURITY OF INFORMATION</strong>
                    We take security seriously and take numerous precautions to protect the security of Personally Identifiable Information. You can access your Personally Identifiable Information on our Website through a password and your email address. This password is encrypted. We recommend that you do not share your password with anyone. In addition, your Personally Identifiable Information resides on a secure server that only selected personnel and contractors have access to. We encrypt certain sensitive information (such as credit card information) using Secure Socket Layer (SSL) technology to ensure that your Personally Identifiable Information is safe as it is transmitted to us. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we employ commercially reasonable security measures to protect data and seek to partner with companies which do the same, we cannot guarantee the security of any information transmitted to or from the Website, and are not responsible for the actions of any third parties that may receive any such information.
                </li>

                <li className="mb-3">
                    <strong className="d-block mb-2">DATA TRACKING</strong>
                    To facilitate and customize your experience with the Website, we store cookies on your computer. A cookie is a small text file that is stored on a User’s computer for record-keeping purposes which contains information about that User. We use cookies to save you time while using the Website, remind us who you are, and track and target User interests in order to provide a customized experience. Cookies also allow us to collect Non-Personally Identifiable Information from you, like which pages you visited and what links you clicked on. Use of this information helps us to create a more user-friendly experience for all visitors. Most browsers automatically accept cookies, but you may be able to modify your browser settings to decline cookies. Please note that if you decline or delete these cookies, some parts of the Website may not work properly.
                </li>

                <li className="mb-3">
                    <strong className="d-block mb-2">PRIVACY POLICIES OF THIRD PARTY WEBSITES</strong>
                    This Privacy Policy only addresses the use and disclosure of information we collect from you on https://www.screens.my. Other websites that may be accessible through this Website have their own privacy policies and data collection, use and disclosure practices. If you link to any such website, we urge you review the website’s privacy policy. We are not responsible for the policies or practices of third parties.
                </li>

                <li className="mb-3">
                    <strong className="d-block mb-2">MISCELLANEOUS PRIVACY ISSUES</strong>
                    Minors under the age of 18 may not use the Website. We do not knowingly collect personal information from anyone under the age of 18, and no part of the Website is designed to attract anyone under the age of 18. Ounch does not sell products for purchase by children.
                </li>

                <li className="mb-3">
                    <strong className="d-block mb-2">CHANGES TO PRIVACY POLICY</strong>
                    If we decide to change our privacy policy, we will post those changes to this privacy statement, the home page, and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.
                </li>

                <li className="my-5">- END -</li>
            </ul>
        </div>
    );
}
 
export default Privacy;