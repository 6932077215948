import Curve from '../Curve';
import useProducts from '../../hooks/useProducts';
import Loader from '../Common/Loader';
import ProductCell from '../Common/ProductCell';

const ProductsSection = () => {

    const { isLoading, products } = useProducts();

    if(isLoading){
        return (
            <Loader />
        )
    }

    return (
        <section className="section py-5 bg-light">
            <Curve className="color-body" />

            <div className="container pt-6 pb-4 text-center" style={{ position: 'relative', zIndex: 2 }}>


                <div className="row align-items-center mt-3 mt-md-0">

                    <div className="col-lg-4 col-md-5 text-center text-md-start mb-5 mb-md-0">
                        <h2 className="mb-3">Featured Products</h2>
                        <p className="text-muted mb-5 lead">We bundle the best software with the most reliable and robust digital signage hardware to deliver the user-experience you deserve.</p>
                    </div>

                    <div className="col-lg-8 col-md-7 d-flex align-items-center flex-column bg-shape">

                        <div className="row align-items-center justify-content-center">

                            <div className="col-12 col-sm-6 col-md-4">
                                {products.slice(0, 2).map((item, index) => (
                                    <ProductCell
                                        key={index} 
                                        index={0} 
                                        icon={item.image} 
                                        url={`/products/${item.id}`} 
                                        title={item.title} 
                                        text={item.text} />
                                ))}
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                {products.slice(2, 5).map((item, index) => (
                                    <ProductCell
                                        key={index}
                                        index={0}
                                        icon={item.image}
                                        url={`/products/${item.id}`}
                                        title={item.title}
                                        text={item.text} />
                                ))}
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </section>
    );
}

export default ProductsSection;
