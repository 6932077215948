import { getSupportEmail, SupportEmail } from "../Common/Helpers";


const FaqSection = () => {

    return (
        <section className="section py-5 bg-white">

            <div className="container pt-6 pb-4 text-center" style={{ position: 'relative', zIndex: 2 }}>
                <h2 className="mb-3">Frequently Asked Questions</h2>
                <p className="text-muted mb-5 lead">A Proven Solution, it's Super Easy, it's Secure and comes with the Best Support.</p>

                <div className="row justify-content-center">
                    <div className="col-12 col-md-9 col-lg-7">

                        <div className="accordion accordion-flush" id="accordionFAQ">

                            {
                                questionsAnswers.map((item, index) => (
                                    <div className="accordion-item " key={index}>
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={`#faq-collapse-${index}`}>{item.question}</button>
                                        </h2>

                                        <div className={`accordion-collapse collapse`} id={`faq-collapse-${index}`} data-bs-parent="#accordionFAQ">
                                            <div className="accordion-body text-start" dangerouslySetInnerHTML={{ __html: item.answer }}></div>
                                        </div>

                                    </div>
                                ))
                            }

                        </div>

                    </div>
                </div>

            </div>
        </section>
    );
}

export default FaqSection;


const questionsAnswers = [
    // {
    //     question: "What is included in the 'Lifetime Plan'",
    //     answer:
    //         `Within 14 days of purchase, if for whatever reason you are not completely satisfied with your Screens solution, let us know by sending an email to ${getSupportEmail()} and you can arrange to send the full/complete set of the hardware to the Ounch’s registered business address. <a href="${process.env.PUBLIC_URL}/legal/money-back">T&C applies. Please read full text</a>.`
    // },
    {
        question: "What is included in the Lifetime Access",
        answer:
            `All Screens devices come with lifetime access to our cloud-based Content Management System. Unlike traditional subscription plans where payment has to be made monthly or annually, you will only be charged a one-time fee initially in order to gain access to our system during the lifetime of the device. Note that all device comes with OEM license, which is not transferable. You may not remove the software from the device it was installed on originally and install on another, however you are allowed to transfer ownership to a new user if required.`
    },
    {
        question: "What can I create with Screens?",
        answer:
            "Actually the skies the limit. You can create an unlimited number of screen layouts. Add slideshows, and RSS feeds, scrolling text, dynamic media arrangements, weather forecasts, and more.",
    },
    {
        question: "How can I remotely manage my devices?",
        answer: `You can effortlessly manage your digital signage devices from any browser, at any time and instantly send updates to your screen. Screen’s CMS allows you to update as many screens as you wish from anywhere. And most importatly witin minuites of making an update, the content updates.`,
    },
    {
        question: "What type of TV should I use?",
        answer: `Screens will work with any TV or any screen of any brand or size that has an HDMI input. But for best visual effect we do have some recommendations. Give us a call, we are more than happy to advice.`,
    },
    {
        question: "Don't worry! We are always here for you.",
        answer: `Email support is available 24/7. Chat and phone lines are open during normal business hours.`,
    },
];