
import { ReactComponent as IconSoftware } from '../../assets/icons/icon_software.svg';
import { ReactComponent as IconPlugplay } from '../../assets/icons/icon_plugnplay.svg';
import { ReactComponent as IconTemplates } from '../../assets/icons/icon_templates.svg';

const WhatSection = () => {
    return (
        <section className="section py-5">
            <div className="container pt-6 pb-4 text-center">
                <h2 className="mb-3">What can Screens do for me?</h2>
                <p className="text-muted mb-5 lead">We are the Digital Signage Specialists. <br />With a simple setup, Screens will help you change the way you visually communicate with your customers.</p>

                <div className="row justify-content-center mb-5">
                    <div className="col-12 col-md-6">
                        <div className="mock-tv d-inline-block ratio ratio-16x9">
                            <video src={`${process.env.PUBLIC_URL}/assets/videos/showcase.mp4?v=${process.env.REACT_APP_VERSION || '0.0.1'}`} playsInline muted autoPlay allowFullScreen loop ></video>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="svg-icon lg">
                            <IconSoftware />
                        </div>
                        <h4 className="mb-3">Powerful Software</h4>
                        <p className="text-muted">Cloud-based content manager allows for instant content updates, anytime, anywhere.</p>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="svg-icon lg">
                            <IconPlugplay />
                        </div>
                        <h4 className="mb-3">Plug &amp; Play Device</h4>
                        <p className="text-muted">Quickly and easily deploy our Plug-&-Play devices to your TVs.</p>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="svg-icon lg">
                            <IconTemplates />
                        </div>
                        <h4 className="mb-3">Ready-to-use Templates</h4>
                        <p className="text-muted">Hit the ground running with specially crafted templates you can choose from.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default WhatSection;