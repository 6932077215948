import FaqSection from "../../../components/SectionFAQ";
import ReadyStart from "../../../components/Common/ReadyStart";

import HeroImage from '../../../assets/hero-images/Education-Hero-IMG.png';
import PageHeader from "../../../components/Common/PageHeader";
import Icons from "../../../components/Common/Icons";
import PageDescription from "../../../components/Common/PageDescription";
import Curve from "../../../components/Curve";
import SectionBox from "../../../components/Common/SectionBox";

const Education = () => {
    return (
        <>
            <PageHeader
                title="Digital Signage for Education Institutions"
                text="Screens provides a one-of-a-kind, simple-to-use and-operate Digital Signage System for educational institutions of all types."
                image={<img src={HeroImage} className="img-fluid" />} />

            <PageDescription title="All that Information, on a Single Screen.">
                <p>Are you still printing your notices and pasting them on bulletin boards around your school? Not only is this “not green”, but it is also very tedious and labor-intensive.</p>
                <p className="mb-0">Imagine the improved efficiency that you will get by directly publishing your content (Posters and Photos) to multiple screens all over the campus with a few mouse clicks? In the current digital age, providing more engaging content like videos of various school activities are the way to go.</p>
            </PageDescription>

            <section className="section py-5">
                <div className="container py-6 pb-4 text-center">
                    <h2 className="mb-3">We offer everything you will need</h2>
                    <p className="mb-5 lead">You can easily create content and upload it to your screens using our online digital signage software and the included templates. Within minutes, all changes are wirelessly updated!</p>

                    <div className="row">
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Upcoming Events"
                                text="Share information about upcoming evens at your school,  post announcements, new services or changes to school schedules."
                                icon={<Icons icon="EducationEvents" />} />
                        </div>

                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Welcome Notices"
                                text="Display special welcome notices to make special guests like alumni, visitors, and honorary guests feel welcome."
                                icon={<Icons icon="EducationWelcome" />} />

                        </div>
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Global News"
                                text="Using Screens improve school standards and student knowledge by displaying news headlines from your favorite websites using RSS feeds or social media."
                                icon={<Icons icon="EducationNews" />} />

                        </div>
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Campus News"
                                text="Keep students up to date on everything going on on campus, including activities, classes, competitions, and more."
                                icon={<Icons icon="EducationCampus" />} />
                        </div>
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="What's On the Menu"
                                text="Show what's for lunch, snacks, and their prices, as well as nutritional information, limited-time specials, new appetisers, dessert specials, and other promotions."
                                icon={<Icons icon="EducationMenu" />} />
                        </div>
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Scheduling Change"
                                text="Changes in class schedules, cancellations, and other important scheduling information should be effectivly communicated to students."
                                icon={<Icons icon="EducationSchedule" />} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section py-5 mt-5">
                <Curve position="slope-left" className="color-secondary" />
            </section>

            <section className="section py-5 bg-light">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6">
                            <h2 className="mb-3">Digital Age Newspaper</h2>
                            <p className="mb-5 lead">A newspaper in digital format. Even a school-aged child can operate Screens because it is so simple to use and operate. Create your own screens or use one of the built-in educational templates to let students fill in the blanks.</p>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="px-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/concept-images/education-1.png?v=${process.env.REACT_APP_VERSION || '0.0.1'}`} className="img-fluid rounded shadow" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="section py-5 bg-light">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 order-md-2">
                            <h2 className="mb-3">Emergency Alerts</h2>
                            <p className="mb-5 lead">When there is an Emergency, one of the most difficult tasks is informing everyone about the situation. The first step in managing an emergency should be to send timely, accurate information to everyone in the organisation as soon as possible.</p>
                        </div>
                        <div className="col-12 col-md-6 order-md-1">
                            <div className="px-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/concept-images/education-2.png?v=${process.env.REACT_APP_VERSION || '0.0.1'}`} className="img-fluid rounded shadow" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="section py-5 bg-light">
                <div className="container pb-6">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6">
                            <h2 className="mb-3">Student Voices</h2>
                            <p className="mb-5 lead">Ask a multiple-choice question, such as: Who should be the next President of your Student Council? Or what kind of activity do you want to do on the last day of school? Students can vote using their smartphones, and the results will be updated in real time on the screen.</p>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="px-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/concept-images/education-3.png?v=${process.env.REACT_APP_VERSION || '0.0.1'}`} className="img-fluid rounded shadow" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <ReadyStart />

            <FaqSection />
        </>
    );
}

export default Education;