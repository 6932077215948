import { SupportEmail } from "../../components/Common/Helpers"


const Returns = () => {
    return (
        <div className="terms-text mb-3">
            <p>We will refund your money once we have received it and assessed that the hardware is to our satisfaction. The refund will be sent back <strong>via online bank transfer</strong> according to the bank details given by the customer. </p>
            <p>
                <strong>Refund Process</strong>
            </p>
            <ol>
                <li>Send Email to <SupportEmail/> </li>
                <li>The customer will receive an email confirmation of the refund. Received email notification on the refund.</li>
                <li>Deliver the hardware to Ounch’s registered return business address stated in the email.</li>
                <li>Refund will take approximately 7 working days.</li>
            </ol>
            <p>
                <strong>
                    <em>Price</em>
                </strong>: The prices for products available for purchase through the website will be displayed to you on the website. The prices displayed include shipping cost and taxes.All monetary transactions on the website take place in MYR (Malaysian Ringgit).
	</p>
            <p>
                <strong>
                    <em>Product availability</em>
                </strong>: Ounch reserves the right without prior notice to discontinue or change specifications on products and services offered on this website without incurring any obligations. Ounch cannot guarantee product availability for all products at all times. Estimated back-in-stock dates are not guaranteed or confirmed. Customer purchases and orders are fulfilled on a first come, first served, basis.
	</p>
            <p>
                <strong>
                    <em>Warranty</em>
                </strong>: Ounch’s Hardware is covered by a 1-year limited warranty from the day of the delivery to your house. This warranty is not transferable and covers only the original purchaser who continues to be the owner of the product.
	</p>
            <p>This limited warranty is extended to the original purchaser only, and excludes coverage of any damage resulting from improper use or handling, accidental damage, modifications or unauthorized repairs, normal wear &amp; tear during use as intended, or other causes that are not defects in material and workmanship.</p>
            <p>In case of a covered defect, you must return the product and purchase receipt to Ounch Sdn Bhd to obtain warranty coverage. Ounch Sdn Bhd will repair or replace the defective product, at its sole discretion. This limited warranty extends only to authentic Screen’s hardware and sold by Ounch Sdn Bhd or its authorized resellers.</p>
            <p>This product is otherwise sold as-is with all faults. Ounch Sdn Bhd. specifically disclaims all other warranties, conditions, representations and the provision of or failure to provide any support or other service, information, software, or related content through the product. This limited warranty excludes all other express or implied warranties of any type, all of which are specifically disclaimed.</p>
            <p>Any warranty imposed by operation of law, or course of trade or dealing, shall be limited to the three hundred sixty five (365) day term of this limited warranty. Ounch Sdn Bhd’s liability for any direct or indirect, incidental or consequential damages arising out of the use of the product, or the failure or inability to use the product shall be limited to the original purchase price of the product. No person may change or expand the terms of this limited warranty.</p>
            <p>Some jurisdictions do not allow a limitation on implied warranties or the amount or type of damages, so these limitations may not apply to you. This limited warranty gives you specific legal rights, and you may have additional rights that may vary.</p>
            <p>
                <strong>Any warranty matters, please email to <SupportEmail/></strong>
            </p>
        </div>
    );
}
 
export default Returns;