import { SupportEmail } from "../../components/Common/Helpers"


const Lifetime = () => {
    return (
        <div className="terms-text mb-3">
            <p className="lead pb-4">All Screens devices come with lifetime access to our cloud-based Content Management System. </p>
            <p className="mb-4">Unlike traditional subscription plans where payment has to be made monthly or annually, you will only be charged a one-time fee initially in order to gain access to our system during the lifetime of the device. </p>
            <p className="mb-4">Note that all device comes with OEM license, which is not transferable. You may not remove the software from the device it was installed on originally and install on another, however you are allowed to transfer ownership to a new user if required.</p>
            <p className="mb-4">If you require any further information, feel free to contact us by sending an email to <SupportEmail />.</p>
        </div>
    );
}

export default Lifetime;