import useScript from "../../hooks/useScript";
import './index.scss';


const ThreeSixityView = props => {

    useScript(`${process.env.PUBLIC_URL}/assets/cloudimage-360@2.1.0/js-cloudimage-360-view.min.js`);

    // rest of your component
    return (
        <div
            className="cloudimage-360"
            data-folder={props.folder}
            data-filename={props.filename}
            data-amount={props.amount}
            data-magnifier={props.magnifier}
            data-hide-360-logo="true"
            data-full-screen="false"
            data-logo-src="./360_view.svg"
        >
            <button className="cloudimage-360-prev" />
            <button className="cloudimage-360-next" />
        </div>
    );
};



export default ThreeSixityView;
