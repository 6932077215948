import { NavLink } from "react-router-dom";


const ProductCell = (props) => {
    return (
        <div data-aos="fade-in" data-aos-delay={`${props.index * 10}`} className="bg-white box-shadow-lg mb-4 card rounded-3 h-100 position-relative">
        <img className="card-img-top rounded-3" src={props.icon} alt="Icon" />
            <div className="card-body">
                <h3 className="h5 mb-2 text-start">{props.title}</h3>
                <div className="d-flex">
                    {
                        props.url ?
                            <NavLink to={props.url} className="btn btn-link position-relative d-flex p-0 align-items-center">
                                <p className="text-start text-muted pe-3 m-0"><small>{props.text}</small></p>
                                <i className="mdi mdi-arrow-right mdi-18px"></i>
                            </NavLink>
                        : 
                            <p className="text-start text-muted pe-3 m-0"><small>{props.text}</small></p>
                    }
                </div>
            </div>
        </div>
    )
}

export default ProductCell;