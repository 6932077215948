
import ImageRetail from '../assets/home-solutions/Retail.jpg'
import ImageCafe from '../assets/home-solutions/Restaurant.jpg'
import ImageHotel from '../assets/home-solutions/Hospitality.jpg'
import ImageClinic from '../assets/home-solutions/Healthcare.jpg'
import ImageEduca from '../assets/home-solutions/Education.jpg'
import ImageCorp from '../assets/home-solutions/Corporate.jpg'
import ImageCovid from '../assets/home-solutions/Covd19SOP.jpg'

const SolutionsList = [
    {
        image: ImageCovid,
        caption: 'Covid 19',
        link: "/solutions/covid-sop"
    },
    {
        image: ImageRetail,
        caption: 'Retail',
        link: "/solutions/retail"
    },
    {
        image: ImageEduca,
        caption: 'Education',
        link: "/solutions/education"
    },
    {
        image: ImageClinic,
        caption: 'Healthcare',
        link: "/solutions/healthcare"
    },
    {
        image: ImageCorp,
        caption: 'Corporate',
        link: "/solutions/corporate"
    },
    {
        image: ImageCafe,
        caption: 'Restaurants',
        link: "/solutions/restaurants"
    },
    {
        image: ImageHotel,
        caption: 'Hospitality',
        link: "/solutions/hospitality"
    }
]

export default SolutionsList;