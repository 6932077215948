import Icons from "./Icons"

export const Widgets = () => {
    return (
        <>
            <div className="svg-icon">
                <Icons icon="IconWidgets" />
            </div>
            <p className="mb-3 ftw-600">Range of Widgets</p>
            <small>Screens includes over 10+ drag-and-drop widgets that you can choose from.</small>
        </>
    )
}

export const Customizable = () => {
    return (
        <>
            <div className="svg-icon">
                <Icons icon="IconPowerEditor" />
            </div>
            <p className="mb-3 ftw-600">Fully Customizable</p>
            <small>Resize, reposition, and configure images, messages and other media to your heart's desire.</small>
        </>
    )
}

export const Multimedia = () => {
    return (
        <>
            <div className="svg-icon">
                <Icons icon="IconMultimedia" />
            </div>
            <p className="mb-3 ftw-600">Total File Support</p>
            <small>Supports an extensive list of file types to display images, videos, maps, and directories.</small>
        </>
    )
}

export const DragDrop = () => {
    return (
        <>
            <div className="svg-icon">
                <Icons icon="IconPowerEditor" />
            </div>
            <p className="mb-3 ftw-600">Drag & Drop Widgets</p>
            <small>Users with no prior IT experience can easily create and manage content with Screens' drag-and-drop widgets.</small>
        </>
    )
}

export const DisplayFlex = () => {
    return (
        <>
            <div className="svg-icon">
                <Icons icon="IconDisplayFlex" />
            </div>
            <p className="mb-3 ftw-600">Drag & Drop Widgets</p>
            <small>Screens will seamlessly work with any screen resolution and aspect ratio.</small>
        </>
    )
}

export const Schedule = () => {
    return (
        <>
            <div className="svg-icon">
                <Icons icon="IconDisplayFlex" />
            </div>
            <p className="mb-3 ftw-600">Multi-Layered Schedules</p>
            <small>Set play duration, pre-schedule repeat or one-time play. And update within seconds.</small>
        </>
    )
}





