import React from 'react';
import './index.scss';

const Curve = (props) => {

    const swtchShape = (pos) => {
        switch (pos) {
            case 'top':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
                        <path fill="currentColor" d="M3000,185.4V0H0v185.4C496.4,69.8,996.4,12,1500,12S2503.6,69.8,3000,185.4z"></path>
                    </svg>
                  )
        
            case 'slope-right':
                return (
                    <svg viewBox="0 0 2880 480" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z" fill="currentColor"></path>
                    </svg>
                )

            case 'slope-left':
                return (
                    <svg viewBox="0 0 2880 480" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M720 0c720 240 1440 240 1440 240h720v240H0V0h720z" fill="currentColor"></path>
                    </svg>
                )

            case 'atlassian':
            case 'atlassian-inverse':
                return (
                    <svg viewBox="10 -20 1870 210" xmlns="http://www.w3.org/2000/svg"> 
                        <path fill="currentColor" d="M977.9,76.2 C475.2,-17.4 0.2,132.5 0.2,132.5 L0.2,275.5 L1891.3,275.5 L1891.3,0.7 C1891.3,0.7 1480.6,169.8 977.9,76.2 Z" id="Path"></path>
                    </svg>
                )
        
            default:
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
                        <path fill="currentColor" d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z"></path>
                    </svg>
                )
        }
    }

    return (
        <div className={`cs-shape cs-shape-${props.position} cs-shape-curve ${props.className}`} >
            { swtchShape(props.position) }
        </div>
    );
}

Curve.defaultProps = {
    position: 'bottom',
    className: ''
};
 
export default Curve;