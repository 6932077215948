

const Loader = () => {
    return (
        <div>
            Laoding ...
        </div>
    );
}
 
export default Loader;