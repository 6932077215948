

const Shipping = () => {
    return (
        <div className="terms-text mb-3">
            <p>Free delivery to <strong>West Malaysia</strong> by third- party delivery service providers.In purchasing of our product, you agree to the delivery conditions as below:
	        </p>
            <p>Delivery date &amp;&nbsp; time is subject to our third-party delivery partner’s and cannot be scheduled.</p>
            <ul>
                <li>The delivery time frame may be longer during peak sales seasons such as and not limited to Hari Raya, Christmas, Chinese New Year, and more due to the high demand received by our third-party delivery partner. We will try our best to deliver within our normal delivery time frame, however, as the deliveries are made by a third-party delivery partner, we do not take any responsibility for any late delivery cases during high sales seasons.</li>
                <li>Any additional costs to Ounch such as and not limited to on-demand delivery or second time delivery will be charged to the customer.</li>
                <li>Delivery dates and times are entirely subject to availability and scheduling by our <strong className="d-inline-block m-0">third-party delivery partner, and not Ounch</strong>.</li>
                <li>Delivery charges to East Malaysia will be calculated based on the weight of the parcel and will inform the customer prior to delivery. The customer will need to pay for the delivery charges in order to proceed with the delivery.</li>
                <li>A maximum of 2 attempts for delivery/shipping, after which your order will be cancelled.</li>
            </ul>
        </div>
    );
}
 
export default Shipping;