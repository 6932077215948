import FaqSection from "../../../components/SectionFAQ";

import HeroImage from '../../../assets/hero-images/Retail-Hero-IMG-fashion.png';
import PageHeader from "../../../components/Common/PageHeader";
import ReadyStart from "../../../components/Common/ReadyStart";
import PageDescription from "../../../components/Common/PageDescription";
import { Widgets, Customizable, Multimedia, DragDrop, DisplayFlex, Schedule } from "../../../components/Common/Features";
import Curve from "../../../components/Curve";

import KioskStandee from '../../../assets/devices/Kiosk Retail Features.png';

const Retail = () => {
    return (
        <>
            <PageHeader
                title="Retail Digital Signage"
                text="Retail Digital Signage creates an engaging and immersive shopping experience that helps your brand stand out from the crowd. Use Screens to improve your ability to naturally promote products, upsell services, and create new ways to connect with your customers."
                image={<img src={HeroImage} className="img-fluid" />} />

            <PageDescription title="Influence customers' purchasing decisions while enriching their shopping experience.">
                <p>We understand that you know your products/services inside out, but you should not assume that everyone knows them as well as you do. So, promote your products/services and tell shoppers why they are better than your competitors’. Highlight the key points using digital screens to educate and inspire shoppers so that they will have a lasting impression of your products & your brand.</p>
                <p className="mb-0">Using Retail Digital Signage, you can strategically reach your customers at the vital moment of their shopping trip!</p>
            </PageDescription>

            <section className="section py-5">
                <div className="container pt-6 pb-4">

                    <div className="row">

                        <div className="col-12 col-md-6">
                            <div className="px-1 px-md-4">
                                <h2 className="mb-3">Retail Digital Signage can help you</h2>
                                <p className="mb-3 lead">You can effortlessly create, manage, and broadcast your entire marketing communications with Screens, and you can seamlessly integrate a mixture of customer-centric alerts and information. You can use Screens to easily meet and connect with your customers at any touchpoint, from your entrance to product divisions and aisles.</p>
                                <p>Screens can help to:</p>
                                <ul>
                                    <li>Distribute audience-specific and important location-based communications</li>
                                    <li>Through in-store digital signage, you can draw attention to exclusive deals that are only available for a limited time</li>
                                    <li>Promote upcoming purchases, special events, and promotions</li>
                                    <li>Share customer-driven notifications and details on-demand</li>
                                    <li>Reiterate the company's values and customer service practises to build brand trust</li>
                                </ul>
                                <p className="mb-3 lead">You can design, edit, and manage one or all of your screens using Screens Digital Signage system in a simple and confident manner. Screens' All-in-One Digital Signage system can display signage in breakrooms, near check-out, and all the aisles.</p>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 text-center">
                            <div className="px-1 px-md-4">
                                <div className="mb-5">
                                    <img src={`${process.env.PUBLIC_URL}/assets/concept-images/Retail-Layout-P2.1.jpg?v=${process.env.REACT_APP_VERSION || '0.0.1'}`} className="img-fluid rounded shadow" />
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <Widgets />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <Customizable />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <Multimedia />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            <section className="section py-5 mt-5">
                <Curve position="atlassian" className="color-secondary" />
            </section>

            <section className="section py-5 bg-light">
                <div className="container pb-5">
                    <div className="row align-items-start text-center text-md-start">

                        <div className="col-12 col-md-5">
                            <h2 className="mb-3">Schedule and control</h2>
                            <p className="mb-5 lead">From any device, on the browser, you can remotely edit, update and manage all of your content and devices. Using Screens' PowerEditor and the scheduling module you can broadcast new content instantly or preschedule your content to play on specific dates and times.</p>
                            
                            <div className="row text-center">
                                <div className="col-12 col-md-4">
                                    <DragDrop />
                                </div>
                                <div className="col-12 col-md-4">
                                    <DisplayFlex />
                                </div>
                                <div className="col-12 col-md-4">
                                    <Schedule />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-7">
                            <div className="bg-white box-shadow-lg rounded-3 card border-0">
                                <div className="card-body">
                                    <img src={KioskStandee} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <ReadyStart />

            <FaqSection />
        </>
    );
}

export default Retail;