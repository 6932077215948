

import { ReactComponent as IconPowerEditor } from '../../assets/icons/icon_powereditor.svg';
import { ReactComponent as IconDisplayFlex } from '../../assets/icons/icon_displayflexibility.svg';
import { ReactComponent as IconWidgets } from '../../assets/icons/icon_widgets.svg';
import { ReactComponent as IconSchedule } from '../../assets/icons/icon_scheduling.svg';
import { ReactComponent as IconMultimedia } from '../../assets/icons/icon_multimedia.svg';
import { ReactComponent as IconIntegrations } from '../../assets/icons/icon_integrations.svg';
import { ReactComponent as IconCloudbased } from '../../assets/icons/icon_cloudbased.svg';

import { ReactComponent as EducationEvents } from '../../assets/icons/education/icon_upcomingevents.svg';
import { ReactComponent as EducationCampus } from '../../assets/icons/education/icon_campusnews.svg';
import { ReactComponent as EducationNews } from '../../assets/icons/education/icon_globalnews.svg';
import { ReactComponent as EducationSchedule } from '../../assets/icons/education/icon_schedulingchange.svg';
import { ReactComponent as EducationWelcome } from '../../assets/icons/education/icon_welcomenotices.svg';
import { ReactComponent as EducationMenu } from '../../assets/icons/education/icon_whatonthemenu.svg';

import { ReactComponent as HotelAmenities } from '../../assets/icons/hospitality/icon_amenities.svg';
import { ReactComponent as HotelGuides } from '../../assets/icons/hospitality/icon_hotelguides.svg';
import { ReactComponent as HotelMeetrooms } from '../../assets/icons/hospitality/icon_meetingroom.svg';
import { ReactComponent as HotelReception } from '../../assets/icons/hospitality/icon_reception.svg';
import { ReactComponent as HotelRestaurant } from '../../assets/icons/hospitality/icon_restaurants.svg';
import { ReactComponent as HotelWayfinding } from '../../assets/icons/hospitality/icon_wayfinding.svg';

import { ReactComponent as RestaurantMenus } from '../../assets/icons/restaurant/icon_digitalmenus.svg';
import { ReactComponent as RestaurantDrinks } from '../../assets/icons/restaurant/icon_drinksspecial.svg';
import { ReactComponent as RestaurantLoyal } from '../../assets/icons/restaurant/icon_loyaltyprogrammes.svg';
import { ReactComponent as RestaurantInfo } from '../../assets/icons/restaurant/icon_nutritioninformation.svg';
import { ReactComponent as RestaurantPromo } from '../../assets/icons/restaurant/icon_promotehighmarginltos.svg';
import { ReactComponent as RestaurantScial } from '../../assets/icons/restaurant/icon_socialmediapresence.svg';

import { ReactComponent as CorporateAlerts } from '../../assets/icons/corporate/icon_alertsandnotifications.svg';
import { ReactComponent as CorporateSOP } from '../../assets/icons/corporate/icon_bestpracticesandsops.svg';
import { ReactComponent as CorporateEvents } from '../../assets/icons/corporate/icon_companyevents.svg';
import { ReactComponent as CorporateEmply } from '../../assets/icons/corporate/icon_enhanceemployeeengagement.svg';
import { ReactComponent as CorporateHR } from '../../assets/icons/corporate/icon_hrbulletins.svg';
import { ReactComponent as CorporateVisit } from '../../assets/icons/corporate/icon_improvevisitorexperiences.svg';


const Icons = ({icon}) => {
    switch (icon) {

        case 'IconWidgets':
            return (<IconWidgets />);

        case 'IconCloudbased':
            return (<IconCloudbased />);

        case 'IconPowerEditor':
            return (<IconPowerEditor />);

        case 'IconMultimedia':
            return (<IconMultimedia />);

        case 'IconDisplayFlex':
            return (<IconDisplayFlex />);

        case 'IconSchedule':
            return (<IconSchedule />);

        case 'IconIntegrations':
            return (<IconIntegrations />);
    
        case 'EducationEvents':
            return (<EducationEvents />);

        case 'EducationCampus':
            return (<EducationCampus />);

        case 'EducationNews':
            return (<EducationNews />);

        case 'EducationSchedule':
            return (<EducationSchedule />);

        case 'EducationWelcome':
            return (<EducationWelcome />);

        case 'EducationMenu':
            return (<EducationMenu />);
    
        case 'HotelAmenities':
            return (<HotelAmenities />);

        case 'HotelGuides':
            return (<HotelGuides />);

        case 'HotelMeetrooms':
            return (<HotelMeetrooms />);

        case 'HotelReception':
            return (<HotelReception />);

        case 'HotelRestaurant':
            return (<HotelRestaurant />);

        case 'HotelWayfinding':
            return (<HotelWayfinding />);
    
        case 'RestaurantMenus':
            return (<RestaurantMenus />);

        case 'RestaurantDrinks':
            return (<RestaurantDrinks />);

        case 'RestaurantLoyal':
            return (<RestaurantLoyal />);

        case 'RestaurantInfo':
            return (<RestaurantInfo />);

        case 'RestaurantPromo':
            return (<RestaurantPromo />);

        case 'RestaurantScial':
            return (<RestaurantScial />);
    
        case 'CorporateAlerts':
            return (<CorporateAlerts />);

        case 'CorporateSOP':
            return (<CorporateSOP />);

        case 'CorporateEvents':
            return (<CorporateEvents />);

        case 'CorporateEmply':
            return (<CorporateEmply />);

        case 'CorporateHR':
            return (<CorporateHR />);

        case 'CorporateVisit':
            return (<CorporateVisit />);
    
        default:
            return (<IconPowerEditor />);

    }
}
 
export default Icons;