

// import { NavLink } from "react-router-dom";
// import useProducts from "../../hooks/useProducts";
// import Loader from "./Loader";
import { useCountRenders } from "../../hooks/useCountRenders";
import SubscribeButton from "./SubscribeButton"


const ProductHeader = ({ id, title, text, image }) => {

    useCountRenders('Product Header');

    // const { isLoading, products } = useProducts();

    // if(isLoading){
    //     return (
    //         <Loader />
    //     )
    // }


    return (
        <section className="section py-5 d-flex align-items-center">
            <div className="container py-6">
                <div className="row align-items-center">
                    {/* <div className="col-12 col-md-3">
                        <ul className="nav nav-pills product-header-nav">

                            {
                                products.slice(0, 3).map((item, index) => (
                                    <li className="nav-item my-3 me-3 w-100 d-block" key={index}>
                                        <NavLink to={`/products/${item.id}`} className="nav-link d-block" activeClassName="active" isActive={() => item.id === id}>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">
                                                    <img src={item.image} width="60" />
                                                </div>
                                                <div className="flex-grow-1 w-100 ps-2 ms-1">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="pe-1">{item.title}</div>
                                                        <i className="mdi mdi-chevron-right mdi-24px"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>
                                ))
                            }
                        </ul>
                    </div> */}
                    <div className="col">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 text-start">
                                <h1 className="mb-3">{title}</h1>
                                <p className="lead mb-5">{text}</p>

                                <SubscribeButton />
                            </div>

                            <div className="col-12 col-md-6">
                                {image}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProductHeader;