

const ProductSpecList = ({ list }) => {
    return (
        <ul className="list-unstyled">
            {
                list.map((item, index) => (
                    <li className="ps-4 mb-2" key={index}>
                        <i className="mdi mdi-check text-success me-2 ms-n4"></i>
                        {item.text}
                    </li>
                ))
            }
        </ul>
    );
}
 
export default ProductSpecList;