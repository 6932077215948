import { Link, useParams } from "react-router-dom";

import './index.scss';

import FaqSection from "../../components/SectionFAQ";
import ProductHeader from "../../components/Common/ProductHeader";
import ReadyStart from "../../components/Common/ReadyStart";

import ProductSummary from "../../components/Common/ProductSummary";
import SolutionsList from "../../services/SolutionsList";
import useDevice from "../../hooks/useDevice";
import Loader from "../../components/Common/Loader";
// import ProductsSection from "../../components/SectionProducts";
import useProducts from "../../hooks/useProducts";
import Curve from "../../components/Curve";
import ProductCell from "../../components/Common/ProductCell";
import { useState } from "react";
import { useCountRenders } from "../../hooks/useCountRenders";
import Wave from "../../components/Wave";


const ProductDetailsPage = () => {
    useCountRenders('Product Details');

    const { id } = useParams();
    const { isLoading, data } = useDevice(id); 
    const { products } = useProducts();
    
    const [activeTab, setActiveTab] = useState(window.location.hash.split('#')[1] || 'features');
    
    if(isLoading){
        return(
            <Loader />
        )
    }

    const setTab = (tab) => {
        setActiveTab(tab);
        window.location.hash = tab;
    }

    return (
        <>
            <ProductHeader
                id={id}
                title={ data.headerTitle }
                text={ data.headerText }
                image={ data.headerImage } />

            <section className="section mb-5 mb-md-5">
                <div className="container bg-overlay-content" style={{ marginTop: '-100px' }} data-aos="fade-up" data-aos-delay="100">

                    <div className="bg-white box-shadow-lg rounded-3 py-2 py-md-4">

                        <div className="row align-items-center">
                            <div className="col-12 col-md-5">
                                <div className="px-2 px-md-4 text-center">
                                    { data.summaryImage }
                                </div>
                            </div>
                            
                            <div className="col-12 col-md-7">
                                <ProductSummary {...data.deviceSummary} />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container bg-overlay-content">
                    <div className="bg-white box-shadow-lg rounded-3 card border-0">
                        <div className="card-body">
                            <div className="row">

                                <div className="col-12 col-md-3 py-4 py-md-5">
                                    <div className="nav flex-column flex-fill nav-pills me-md-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        {/* <a className={`nav-link rounded-pill ${ activeTab === 'features' ? 'active': ''}`} id="v-pills-features-tab" data-bs-toggle="pill" href="#v-pills-features" role="tab" aria-controls="v-pills-features" aria-selected="false">Features</a>
                                        <a className={`nav-link rounded-pill ${ activeTab === 'usecases' ? 'active' : ''}`} id="v-pills-usecases-tab" data-bs-toggle="pill" href="#v-pills-usecases" role="tab" aria-controls="v-pills-usecases" aria-selected="false">Usages</a>
                                        <a className={`nav-link rounded-pill ${ activeTab === 'technical' ? 'active' : ''}`} id="v-pills-technical-tab" data-bs-toggle="pill" href="#v-pills-technical" role="tab" aria-controls="v-pills-tech" aria-selected="false">Technical Spec</a>
                                        <a className={`nav-link rounded-pill ${ activeTab === 'installation' ? 'active': ''}`} id="v-pills-installation-tab" data-bs-toggle="pill" href="#v-pills-install" role="tab" aria-controls="v-pills-install" aria-selected="false">Installation</a> */}
                                
                                        <button className={`nav-link rounded-pill text-start ${activeTab === 'features' ? 'active' : ''}`} onClick={() => setTab('features')}>Features</button>
                                        <button className={`nav-link rounded-pill text-start ${activeTab === 'usecases' ? 'active' : ''}`} onClick={() => setTab('usecases')}>Usages</button>
                                        <button className={`nav-link rounded-pill text-start ${activeTab === 'technical' ? 'active' : ''}`} onClick={() => setTab('technical')}>Technical Spec</button>
                                        <button className={`nav-link rounded-pill text-start ${activeTab === 'installation' ? 'active' : ''}`} onClick={() => setTab('installation')}>Installation</button>    
                                    </div>
                                </div>


                                <div className="col-12 col-md-9">
                                    <div className="tab-content" id="v-pills-tabContent">

                                        <div className={`tab-pane fade ${activeTab === 'features' ? 'show active' : ''}`} id="v-pills-features" role="tabpanel" aria-labelledby="v-pills-features-tab">
                                            <div className="p-3 px-md-4 py-md-5">
                                                <h3 className="mb-5">Features & Technology</h3>

                                                { data.featureSummary }

                                            </div>
                                        </div>
                                        
                                        <div className={`tab-pane fade ${activeTab === 'usecases' ? 'show active' : ''}`} id="v-pills-usecases" role="tabpanel" aria-labelledby="v-pills-usecases-tab">
                                            <div className="p-3 px-md-4 py-md-5">
                                                <h3 className="mb-5">Works Where You Need It To</h3>

                                                { data.usageSummary }

                                                <div className="row row-cols-2 row-cols-md-4 g-4 g-md-5 justify-content-center mb-4">
                                                    {
                                                        SolutionsList.map((item, index) => (
                                                            <Link to={item.link} className="col" key={index}>
                                                                <div className="card card-hover rounded-2 text-center" style={{overflow: 'hidden'}}>
                                                                    <img src={item.image} className="card-img-top"></img>
                                                                    <div className="card-body">{item.caption}</div>
                                                                </div>
                                                            </Link>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className={`tab-pane fade ${activeTab === 'technical' ? 'show active' : ''}`} id="v-pills-technical" role="tabpanel" aria-labelledby="v-pills-technical-tab">
                                            <div className="p-3 px-md-4 py-md-5">
                                                <h3 className="mb-5">Additional Technical Specification</h3>

                                                { data.techSpecSummary }

                                            </div>
                                        </div>
                                        
                                        <div className={`tab-pane fade ${activeTab === 'installation' ? 'show active' : ''}`} id="v-pills-install" role="tabpanel" aria-labelledby="v-pills-install-tab">
                                            <div className="p-3 px-md-4 py-md-5">
                                                <h3 className="mb-5">Installation</h3>

                                                { data.installation }

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="section py-5 bg-ounch mt-n5">
                <Curve position="top" className="color-body" />
            </section> */}

            <section className="section mt-n5">
                <Wave style="style-5" className="bg-ounch-color" />
            </section>

            <section className="section py-5 bg-ounch mt-n5">
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            
                            <div className="row row-cols-1 row-cols-md-3 g-5">
                                {
                                    products.map((item, index) => (
                                        <div className="col" key={index}>
                                            <ProductCell
                                                key={index}
                                                index={0}
                                                icon={item.image}
                                                url={`/products/${item.id}`}
                                                title={item.title}
                                                text={item.text} />
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <ReadyStart />

            <FaqSection />
        </>
    );
}

export default ProductDetailsPage;