import { useEffect, useState } from "react";
import ProductDetails from "../services/ProductDetails";


const useDevice = (deviceId) => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {

        ProductDetails(deviceId)
            .then((resp) => {
                setData(resp);
                setLoading(false);
            }, (error) => {
                console.log(error)
            });

    }, [deviceId])

    return {
        isLoading, 
        data
    }
}

export default useDevice;