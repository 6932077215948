import PropTypes from 'prop-types';
import './index.scss';

const Wizard = ({ steps, currentStep }) => {

    // console.log(typeof currentStep)

    /* const swithHeading = () => {
        return steps[currentStep-1];
    } */

    return (
        <section className="bg-light mb-5 pricing-wizard">
            <div className="container py-2">

 
                <h3 className="pricing-wizard-headings mb-4">
                    {
                        steps.map((step, index) => (
                            <span key={index} className={`${currentStep === (index + 1) ? 'fadeInDown' : 'fadeOutDown'}`}>{step}</span>
                        ))
                    }
                </h3>

                <div className="row text-center">
                    {
                        steps.map((step, index) => (
                            <div className="col" key={index}>
                                <div title={step} className={`progress rounded-pill ${currentStep === (index + 1) ? 'current' : currentStep > (index + 1) ? 'active' : ''} `}>
                                    <div className="progress-bar"></div>
                                </div>
                            </div>
                        ))
                    }
                </div>

            </div>
        </section>
    );
}


Wizard.propTypes = {
    steps: PropTypes.array,
    currentStep: PropTypes.number
};

export default Wizard;