import {
    Switch,
    Route,
    useRouteMatch,
    Redirect,
    useParams
} from "react-router-dom";

import Corporate from "./Corporate";
import Education from "./Education";

import Healthcare from "./Healthcare";
import Hospitality from "./Hospitality";
import Restaurants from "./Restaurants";
import Retail from "./Retail";
import { useEffect } from "react";
import { capitalizeFirstLetter } from "../../components/Common/Helpers";
import CovidSOP from "./CovidSOP";

const SolutionsPage = () => {

    const match = useRouteMatch();
    
    return (
        <>
            <Switch>
                <Route path={`${match.path}/:topicId`}>
                    <Topic />
                </Route>
                <Route path={match.path}>
                    <Redirect to="solutions/covid19" />
                </Route>
            </Switch>
        </>
    );
}

export default SolutionsPage;


function Topic() {
    const { topicId } = useParams();
    const commonString = 'Screens - It\'s digital signage made simple';

    useEffect(() => {

        document.title = `${capitalizeFirstLetter(topicId)} Solutions • ${commonString}`;

        return () => { }

    }, [topicId]);

    switch (topicId) {
        case 'healthcare':
            return <Healthcare />;

        case 'education':
            return <Education />;

        case 'retail':
            return <Retail />;

        case 'corporate':
            return <Corporate />;

        case 'hospitality':
            return <Hospitality />;

        case 'restaurants':
            return <Restaurants />;

        case 'covid19':
            return <CovidSOP />;

        default:
            return <CovidSOP />;
    }
}