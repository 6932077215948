import Slider from "react-slick";

import './index.scss';

// import { ReactComponent as Logo } from '../../assets/logo-screens-full.svg';
import heroImg1 from  './hero-1.png';
import heroImg2 from  './hero-2.png';
import heroImg3 from  './hero-3.png';
import SubscribeButton from '../Common/SubscribeButton';
import { useRef } from "react";

const MainHero = () => {
    const slider = useRef(null);

    return (
        <section id="hero-section" className="section py-5 d-flex align-items-center">
            <div className="container pt-6 pb-4">
                <div className="row align-items-center">
                    <div className="col-12 col-md-5 mt-lg-n7 mb-sm-3 mb-lg-0 text-start pt-5 pt-md-0">
                        {/* <div className="hero-logo mb-3"><Logo /></div> */}
                        <h1 className="display-2 mb-3">Convert Traffic Into Customers</h1>
                        <p className="lead mb-5">Most businesses don’t know how to catch the attention of passing traffic. With Screens, we help transform any TV into a powerful marketing tool, effectively capturing the attention of potential customers for you to communicate your deals, helping you convert traffic into sales.</p>

                        <SubscribeButton />
                    </div>
                    <div className="col-12 col-md-7 d-none d-md-block px-0">
                        <Slider ref={slick => (slider.current = slick)} {...sliderSettings}>
                            <img src={heroImg1} alt="" className="img-fluid" />
                            <img src={heroImg2} alt="" className="img-fluid" />
                            <img src={heroImg3} alt="" className="img-fluid" />
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default MainHero;



const sliderSettings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // speed: 5000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false
};