import FaqSection from "../../../components/SectionFAQ";


import HeroImage from '../../../assets/hero-images/Healthcare-Hero-IMG.png';
import PageHeader from "../../../components/Common/PageHeader";
import ReadyStart from "../../../components/Common/ReadyStart";
import PageDescription from "../../../components/Common/PageDescription";
import { Customizable, Multimedia, Widgets } from "../../../components/Common/Features";


const Healthcare = () => {
    return (
        <>
            <PageHeader 
                title="Healthcare Digital Signage"
                text="A more convenient and innovative way to share information with your patients and staff. With Screens you can create and edit health-related material, such as seasonal medical updates, insurance info, vaccine news, and more!"
                image={<img src={HeroImage} className="img-fluid" />} />

            <PageDescription title="Improve your patient relations and the quality of the visits.">
                <p>Digital signage in healthcare is a powerful new tool that improves patient satisfaction, improves communication with the employees, and reduces the need to print and post new signage.</p>
                <p className="mb-0">It's easier to exchange fresh and time-sensitive details with digital signage for healthcare.</p>
            </PageDescription>    


            <section className="section py-5">
                <div className="container pt-6 pb-4">

                    <div className="row">

                        <div className="col-12 col-md-6 order-md-2">
                            <div className="px-1 px-md-4">
                                <h2 className="mb-3">Healthcare Digital Signage can help you</h2>
                                <p className="mb-3 lead">Using Screens you can create and manage different styles of content across all of your tvs around your hospital, practise, or office. Screens will enhance your lobby, waiting rooms, pathways, cafeterias, and staff breakrooms.</p>                         
                                <p>Screens can help to:</p>
                                <ul>
                                    <li>Improve the standard of care for patients</li>
                                    <li>Improve facility navigation with digital directories</li>
                                    <li>Staff communications of new policies and protocols</li>
                                    <li>Easily update events and staff meeting schedules</li>
                                    <li>Distribute maps and plans for emergency evacuation</li>
                                    <li>Up-sell other products and services offered</li>
                                    <li>Breakroom displays can help employees communicate better</li>
                                </ul>
                                <p className="mb-3 lead">You can use Screens to create and edit messages that are specific to your different audiences and the location of the sign. You're already an expert at creating content with Screens if you've used PowerPoint presentations. It's that simple. Drag and drop a widget onto the layout. After you've added it, you can change the size, make changes to the settings, and add text or an image.</p>
                            </div>
                        </div> 

                        <div className="col-12 col-md-6 order-md-1 text-center">
                            <div className="px-1 px-md-4">
                                <div className="mb-5">
                                    <img src={`${process.env.PUBLIC_URL}/assets/concept-images/Healthcare-IMG-2.jpg?v=${process.env.REACT_APP_VERSION || '0.0.1'}`} className="img-fluid rounded shadow" />
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <Widgets />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <Customizable />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <Multimedia />
                                    </div>
                                </div>
                            </div>
                        </div>    

                    </div>

                </div>
            </section>


            <ReadyStart />

            <FaqSection />
        </>
    );
}

export default Healthcare;