import { useEffect, useState } from "react"
import ProductsList from "../services/ProductList";


const useProducts = () => {
    const [isLoading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);

    useEffect(() => {

        ProductsList().then((resp) => {
            setProducts(resp);
            setLoading(false);
        })

    }, []);

    return {
        isLoading, products
    }
}

export default useProducts;