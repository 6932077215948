import { useEffect, useReducer } from "react";
import NumberFormat from 'react-number-format';
import { configNumberSeperator } from "../Common/Helpers";

const GrandSummary = ({ currency, deviceList, addonList, isCheckingOut, onCheckout, onStep, step, btnCheckOutDisable, btnPaymentDisable }) => {

    // console.log('gs:', deviceList)

    const tax = 0.06;

    const [state, setState] = useReducer((state, newState) => ({
        ...state,
        ...newState
    }), {
        subTotal: 0,
        taxableAmount: 0,
        taxAmount: 0,
        grandTotal: 0
    });
    

    useEffect(() => {

        const calculate = () => {
            let _taxableAmount = 0;

            const handleReducer = (accumulator, currItem) => {
                const itemTotal = (currItem.price.hardware + currItem.price.software) * currItem.qty;

                // If Taxable Item
                if (currItem.price.software && currItem.price.isTaxable){
                    _taxableAmount += currItem.price.software * currItem.qty;
                }

                return accumulator + itemTotal;
            }

            const _subtotalDevice = deviceList.reduce(handleReducer, 0);
            const _subtotalAddons = addonList.reduce(handleReducer, 0);
            const _subtotal = _subtotalDevice + _subtotalAddons;
            const _taxAmount = _taxableAmount * tax;

            setState({
                subTotal: _subtotal,
                taxableAmount: _taxableAmount,
                taxAmount: _taxAmount,
                grandTotal: _subtotal + _taxAmount
            });
        }

        calculate();

    }, [deviceList, addonList]);


    const handleCheckout = (type) => {
        onCheckout({ type: type, ...state });
    }

    const switchButton = () => {
        switch (step) {
            case 2:
                return (
                    <button className={`btn btn-primary btn-lg py-3 ls-8 ${btnCheckOutDisable ? 'pe-none opacity-50 disabled' : ''}`} onClick={() => onStep(3) }>Checkout</button>
                )

            case 3:
                return (
                    <div className={`d-grid ${btnPaymentDisable ? 'pe-none opacity-50 disabled' : ''}`}>
                        <button className={`btn btn-success btn-lg py-3 ls-8 mb-4 ${isCheckingOut ? 'pe-none opacity-50 disabled' : ''}`} onClick={() => handleCheckout('stripe') }>
                            {
                                isCheckingOut ?
                                <span className="spinner-border" role="status" aria-hidden="true"></span>
                                :
                                <span>Pay with Credit Card</span>
                            }
                        </button>

                        <button className={`btn btn-link btn-lg py-3 ls-8 ${isCheckingOut ? 'pe-none opacity-50 disabled' : ''}`} onClick={() => handleCheckout('cash') }>
                            {
                                isCheckingOut ?
                                    <span className="spinner-border" role="status" aria-hidden="true"></span>
                                    :
                                    <small className="text-uppercase">Pay Offline <small className="text-muted d-block" style={{fontSize:'70%'}}>Direct deposit, cheque or online transfer</small></small>
                            }
                        </button>


                    </div>
                )
        
            default:
                return (
                    <button className={`btn btn-primary btn-lg py-3 ls-8`} onClick={() => onStep(2) }>Proceed</button>
                )
        }
    }


    return (
        <div className={`grand-summary ${isCheckingOut ? 'pe-none' : ''}`}>
            <ul className="list-group list-group-flush border-top border-bottom border-light">
                <li className="px-md-5 py-md-3 border-light list-group-item ftw-600">
                    Sub Total 
                    <span className="float-end">
                        <NumberFormat value={state.subTotal} {...configNumberSeperator} prefix={`${currency} `} />
                    </span>
                </li>
                <li className="px-md-5 py-md-3 border-light list-group-item  ftw-600">
                    Taxable Amount
                    <span className="float-end">
                        <NumberFormat value={state.taxableAmount} {...configNumberSeperator} prefix={`${currency} `} />
                    </span>
                </li>
                <li className="px-md-5 py-md-3 border-light list-group-item  ftw-600">
                    SST ({`${tax * 100}%`})
                    <span className="float-end">
                        <NumberFormat value={state.taxAmount} {...configNumberSeperator} prefix={`${currency} `} />
                    </span>
                </li>
            </ul>

            <div className="card-body p-md-5 text-center">
                <h1 className="m-0 mb-4"><small className="text-muted">{currency}</small> <NumberFormat value={state.grandTotal} {...configNumberSeperator} prefix="" /></h1>
                
                <div className="d-grid">
                    { switchButton() }
                </div>

            </div>
        </div>
    );
}
 
export default GrandSummary;