import React from 'react';
import NumberFormat from 'react-number-format';
import { configNumberSeperator } from '../../components/Common/Helpers';


const OrderDeviceList = React.memo(({deviceList, onUpdate}) => {

    // console.log('OrderDeviceList')

    const setQuantity = (device, value) => {
        const _deviceList = [...deviceList];

        const index = _deviceList.map(function (e) { return e.id; }).indexOf(device.id);
            _deviceList[index].qty = value;

        onUpdate(_deviceList);
    }

    const removeDevice = (device) => {
        const _deviceList = [...deviceList];

        const index = _deviceList.map(function (e) { return e.id; }).indexOf(device.id);
            _deviceList.splice(index, 1);

        onUpdate(_deviceList);
    }
    
    return (
        <div className="order-device-list">

            {
                deviceList.map((device, index) => (
                    <div className="row border-top border-light py-3" key={index}>
                        <div className="col-3 d-flex align-items-center">
                            <img src={device.image} className="img-fluid img-thumbnail" />
                        </div>
                        <div className="col-7 px-0">
                            <p className="m-0">{device.title}</p>

                            <div className="d-flex align-items-center">
                                <small className="text-muted" style={{minWidth: '113px'}}>
                                    <NumberFormat value={device.price.hardware + device.price.software} {...configNumberSeperator} />
                                    <i className="mdi mdi-close mx-2"></i> 
                                </small>

                                <input 
                                    type="number" 
                                    className="form-control form-control-sm form-check-inline" 
                                    value={device.qty} 
                                    min="1" 
                                    onChange={(e) => setQuantity(device, e.target.value)} 
                                    style={{width: '50px'}} />
                            </div>
                        </div>
                        <div className="col-2 d-flex align-items-center justify-content-center px-0">
                            <button className="btn btn-link text-danger py-1 hint--top" aria-label="Remove" onClick={() => removeDevice(device)}>
                                <i className="mdi mdi-trash-can-outline mdi-18px"></i>
                            </button>
                        </div>
                    </div>
                ))
            }

        </div>
    );
});
 
export default OrderDeviceList;