import Curve from "../Curve";
import SubscribeButton from "./SubscribeButton";


const ReadyStart = () => {
    return (
        <section className="section py-5 bg-ounch">

            <div className="container py-5 text-center text-light">
                <h1>Ready To Start?</h1>
                <h3 className="mb-5 ftw-300">3 Easy Steps! Effectivly change the way you visually communicate with your customers!</h3>

                <SubscribeButton className="btn-primary" />
            </div>

            <Curve position="bottom" className="color-body" />
        </section>
    );
}
 
export default ReadyStart;