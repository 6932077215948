
import ImageHDMI from '../assets/devices/HDMI.jpg';
import ImageTRNG_1HR from '../assets/devices/TRNG_1HR.jpg';
import { isProductionServer } from '../components/Common/Helpers';

const ProductAddons = () => {
    return new Promise((resolve) => {

        const list = {

            hdmi_cable: {
                id: 'hdmi_cable',
                image: ImageHDMI,
                title: 'HDMI Cable',
                text: 'Incase you need cables to connect device to your tv. (1.5m)',
                meta: {
                    onSale: true,
                    isTopRated: false,
                    isNew: false
                },
                price: {
                    hardware: 15,
                    software: 0,
                    isTaxable: false
                },
                stripe: {
                    price_id: isProductionServer() ? 'price_1IxZCQKaaaDtIuDn4YBQ2RJ4' : 'price_1IxZBHKaaaDtIuDnEACrreXJ',
                    tax_id: isProductionServer() ? 'txr_1I8idhKaaaDtIuDnIiO2GZ87' : 'txr_1Hz0vJKaaaDtIuDnljJKWFRj'
                },
            },

            train_1hr: {
                id: 'train_1hr',
                image: ImageTRNG_1HR,
                title: 'Online Training',
                text: ' 1hr online trainig slot on how to use the system.',
                meta: {
                    onSale: false,
                    isTopRated: false,
                    isNew: false
                },
                price: {
                    hardware: 0,
                    software: 100,
                    isTaxable: true
                },
                stripe: {
                    price_id: isProductionServer() ? 'price_1IxZCqKaaaDtIuDnLI4OZqul' : 'price_1IxZBtKaaaDtIuDnrT3cgZR6',
                    tax_id: isProductionServer() ? 'txr_1I8idIKaaaDtIuDn3xGGM7wT' : 'txr_1HyzGKKaaaDtIuDnPM2zB3wv'
                },
            }

        }

        resolve(list);

    });
}

export default ProductAddons;