import FaqSection from "../../../components/SectionFAQ";

import HeroImage from '../../../assets/hero-images/Corporate-Hero-IMG.png';
import PageHeader from "../../../components/Common/PageHeader";
import PageDescription from "../../../components/Common/PageDescription";
import SectionBox from "../../../components/Common/SectionBox";
import Icons from "../../../components/Common/Icons";
import Curve from "../../../components/Curve";
import ReadyStart from "../../../components/Common/ReadyStart";

const Corporate = () => {
    return (
        <>
            <PageHeader
                title="Digital Signage for Corporate Communications"
                text="Information is crucial to the functions that help the company to stay efficient and productive. It helps to build trust between teams that results in increased productivity and importantly leads to better customer service resulting in happy customers and ultimately benefiting the business."
                image={<img src={HeroImage} className="img-fluid" />} />

            <PageDescription title="A better way to consistently communicate with your employees and customers.">
                <p className="mb-0">Digital Signage for Corporate Communications allows you to communicate more effectively with your employees. You can display real-time information and events, meeting times with schedules, internal corporate scrolling news tickers, and even add customised messages welcoming your clients and guests using Screens corporate communications digital signage software.</p>
            </PageDescription>

            <section className="section py-5">
                <div className="container py-6 pb-4 text-center">
                    <h2 className="mb-3">From Small Business to Large Enterprises</h2>
                    <p className="mb-5 lead">With Screens, you can create rich content for Corporate Communications that includes company messages, media slideshows, HD videos, internal communications, and more. Customize a corporate communications digital signage template or create a template from scratch that includes multiple zones of media and messages.</p>

                    <div className="row">
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Company Events"
                                text="Emphasize upcoming special events and fundraising efforts."
                                icon={<Icons icon="CorporateEvents" />} />
                        </div>

                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Enhance Employee Engagement"
                                text="Employees of the Month, monthly goals, and more can be highlighted."
                                icon={<Icons icon="CorporateEmply" />} />

                        </div>
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Improve Visitor Experiences"
                                text="Add a personal greeting to make your customer's visit memorable."
                                icon={<Icons icon="CorporateVisit" />} />

                        </div>
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="HR Bulletins"
                                text="A message from the CEO, inspirational quotes. Boost your employee moral."
                                icon={<Icons icon="CorporateHR" />} />
                        </div>
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Best Practices and SOPs"
                                text="Promote best practices and refresh employees on SOPs to best optimize performance."
                                icon={<Icons icon="CorporateSOP" />} />
                        </div>
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Alerts and Notifications"
                                text="Send out timely and accurate information in an emergency or any event."
                                icon={<Icons icon="CorporateAlerts" />} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section py-5 mt-5 bg-light">
                <Curve position="top" className="color-body" />
            </section>

            <section className="section py-5 bg-light">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6">
                            <h2 className="mb-3">Improve Campus Wide Corporate Communications Network</h2>
                            <p className="mb-5 lead">You can use Screens' digital signage for corporate communications to quickly and easily create a unified corporate communications infrastructure, from displaying live and engaging corporate social media feeds to essential emergency notifications.</p>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="px-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/concept-images/factory.jpg?v=${process.env.REACT_APP_VERSION || '0.0.1'}`} className="img-fluid rounded shadow" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section py-5 bg-light">
                <div className="container pb-5">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 order-md-2">
                            <h2 className="mb-3">Your One-Stop Shop for Digital Signage in Corporate Communications</h2>
                            <p className="mb-5 lead">Screens can manage your entire organization's visual communications infrastructure, from small 10" tablets outside of conference rooms to large 90" digital signs for your lobby.</p>
                        </div>
                        <div className="col-12 col-md-6 order-md-1">
                            <div className="px-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/concept-images/Corporate-Layout-2.jpg?v=${process.env.REACT_APP_VERSION || '0.0.1'}`} className="img-fluid rounded shadow" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ReadyStart />

            <FaqSection />
        </>
    );
}

export default Corporate;