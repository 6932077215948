import {
	BrowserRouter as Router,
	Switch,
	Route,
	useLocation
} from "react-router-dom";

import MainNav from './components/MainNav';
import MainFooter from "./components/MainFooter";

import HomePage from "./pages/HomePage";
import PricingPage from "./pages/PricingPage";
import SolutionsPage from "./pages/SolutionsPage";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import LegalPage from "./pages/LegalPage";
import { useEffect } from "react";
import ProductsPage from "./pages/ProductsPage";
import { isProductionServer } from "./components/Common/Helpers";
// import CheckoutPage from "./pages/CheckoutPage";

const App = () => {
	console.log('isPS', isProductionServer());

	return (
		<Router 
			basename={ isProductionServer() ? '' : process.env.PUBLIC_URL}
			forceRefresh={true}>
			
			<ScrollToTop />

			<MainNav/>

			<main>
				<Switch>
					<Route path="/legal">
						<LegalPage />
					</Route>

					<Route path="/solutions">
						<SolutionsPage/>
					</Route>
					
					<Route path="/products/:id">
						<ProductDetailsPage />
					</Route>

					<Route path="/products">
						<ProductsPage />
					</Route>

					<Route path="/buy">
						<PricingPage />
					</Route>

					{/* <Route path="/checkout">
						<CheckoutPage />
					</Route> */}
					
					<Route path="/">
						<HomePage/>
					</Route>
				</Switch>
			</main>

			<MainFooter />

		</Router>
	);
}
 
export default App;


// https://reactrouter.com/web/guides/scroll-restoration
const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'instant'
		});
	}, [pathname]);

	return null;
}