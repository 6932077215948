import FaqSection from "../../../components/SectionFAQ";

import HeroImage from '../../../assets/hero-images/Hospitality-Hero-IMG-2.png';
import PageHeader from "../../../components/Common/PageHeader";
import ReadyStart from "../../../components/Common/ReadyStart";
import PageDescription from "../../../components/Common/PageDescription";
import { Widgets, Customizable, DisplayFlex } from "../../../components/Common/Features";
import Curve from "../../../components/Curve";

import KioskStandee from '../../../assets/devices/Kiosk Hotel Features.png';
import SectionBox from "../../../components/Common/SectionBox";
import Icons from "../../../components/Common/Icons";

const Hospitality = () => {
    return (
        <>
            <PageHeader
                title="Hotel Digital Signage"
                text="Hotels, convention hotels, luxury hotels, suite hotels, extended stay hotels, airport hotels, and resort hotels all benefit from Screens' all-in-one digital signage software. Use Screens digital signage to share guest-centric information, highlight promotions, highlight daily events, and more!"
                image={<img src={HeroImage} className="img-fluid" />} />

            <PageDescription title="Enhance guest experiences, upsell services and increase brand loyalty.">
                <p className="mb-0">Digital signage is the catalyst for a new era in the hospitality industry, delivering incredible guest experiences while also improving the industry's image. Rather than being left behind, many hospitality venues are heeding the statistics that show how important digital signage is to visitors.</p>
            </PageDescription>

            <section className="section py-5">
                <div className="container py-6 pb-4 text-center">
                    <h2 className="mb-3">Screens is here for you </h2>
                    <p className="mb-5 lead">All hotels want to offer their guests the best possible experience, and digital screens are an excellent way to do so. Printed materials and direct human interaction with hotel concierges will still be vital parts of any hotel experience, but digital signage provides a range of great benefits to any hotel looking to increase overall customer satisfaction.</p>

                    <div className="row">
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Reception & Lobby"
                                text="Your hotel's reception and lobby areas are crucial not only to your guests' first impression, but also to their overall experience."
                                icon={<Icons icon="HotelReception" />} />
                        </div>

                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Wayfinding"
                                text="Interactive maps and digital signages can help your guests know where they are currently in the building and even provide a step by step guide on how to get where they are trying to go to."
                                icon={<Icons icon="HotelWayfinding" />} />

                        </div>
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Hotel Guides"
                                text="Using digital screens as guides to all of your hotel's exciting entertainment and activities is a great way to keep your guests informed and up to date on the entire experience."
                                icon={<Icons icon="HotelGuides" />} />

                        </div>
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Amenties"
                                text="Share details about your pool, on-site restaurant and bar specials, fitness and business centre hours, and other pertinent information."
                                icon={<Icons icon="HotelAmenities" />} />
                        </div>
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Meeting Rooms"
                                text="Hotels are used for more than just hospitality. Many hotels also have meeting and event rooms available for local businesses. Allow your visitors to book meeting rooms in real time and see when a meeting room is available."
                                icon={<Icons icon="HotelMeetrooms" />} />
                        </div>
                        <div className="col-12 col-md-4">
                            <SectionBox
                                title="Restaurants"
                                text="Unlike physical menus, which can quickly become out of date and cost you time and money to reprint, digital screens provide a quick, efficient, easy, and effective means of announcing new menu additions or changes."
                                icon={<Icons icon="HotelRestaurant" />} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section py-5 mt-5">
                <Curve position="slope-left" className="color-secondary" />
            </section>

            <section className="section py-5 bg-light">
                <div className="container pb-5">
                    <div className="row align-items-start text-center text-md-start">

                        <div className="col-12 col-md-5">
                            <h2 className="mb-3">Cloud-Based Easy Control</h2>
                            <p className="mb-5 lead">You can update and manage all of your hotel's digital signage screens remotely from any device. With a single click, send updates to one or more screens. Playlists can be pre-programmed to play on recurring days, at specific times, and with a set expiration date. A monitoring and reports dashboard is also included, allowing you to view the screen status, playlists, and reporting from afar.</p>

                            <div className="row text-center">
                                <div className="col-12 col-md-4">
                                    <Widgets />
                                </div>
                                <div className="col-12 col-md-4">
                                    <DisplayFlex />
                                </div>
                                <div className="col-12 col-md-4">
                                    <Customizable />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-7">
                            <div className="bg-white box-shadow-lg rounded-3 card border-0">
                                <div className="card-body">
                                    <img src={KioskStandee} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <ReadyStart />

            <FaqSection />
        </>
    );
}

export default Hospitality;