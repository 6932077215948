// https://getwaves.io/
// https://www.blobmaker.app/
// https://zcreativelabs.com/blog/how-to-add-svg-waves-to-your-next-web-project/

import './index.scss'

const Wave = ({ style = 'style-default', absolute=false, className }) => {

    const switchWave = (style) => {
        switch (style) {
            
            case 'style-1':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="currentColor" fillOpacity="1" d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                    </svg>
                )

            case 'style-2':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="currentColor" fillOpacity="1" d="M0,192L60,213.3C120,235,240,277,360,272C480,267,600,213,720,165.3C840,117,960,75,1080,85.3C1200,96,1320,160,1380,192L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
                    </svg>
                )
                

            case 'style-3':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="currentColor" fillOpacity="1" d="M0,128L60,117.3C120,107,240,85,360,106.7C480,128,600,192,720,213.3C840,235,960,213,1080,186.7C1200,160,1320,128,1380,112L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
                    </svg>
                )
                

            case 'style-4':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="currentColor" fillOpacity="1" d="M0,64L120,69.3C240,75,480,85,720,80C960,75,1200,53,1320,42.7L1440,32L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path>
                    </svg>
                )
                

            case 'style-5':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="currentColor" fillOpacity="1" d="M0,256L120,229.3C240,203,480,149,720,144C960,139,1200,181,1320,202.7L1440,224L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
                    </svg>
                )
                
                

            case 'style-6':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="currentColor" fillOpacity="1" d="M0,192L120,181.3C240,171,480,149,720,165.3C960,181,1200,235,1320,261.3L1440,288L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
                    </svg>
                )
                

            case 'style-7':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="currentColor" fillOpacity="1" d="M0,288L120,266.7C240,245,480,203,720,192C960,181,1200,203,1320,213.3L1440,224L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
                    </svg>
                )
                

            case 'style-8':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="currentColor" fillOpacity="1" d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                    </svg>
                )
                
        
            default:
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="currentColor" fillOpacity="1" d="M0,256L60,266.7C120,277,240,299,360,309.3C480,320,600,320,720,320C840,320,960,320,1080,320C1200,320,1320,320,1380,320L1440,320L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
                    </svg>
                )
        }
    }

    return (
        <div className={`wave-container ${absolute ? 'wave-absolute' : ''} wave-${style} ${className || ''}`}>
            { switchWave(style) }
        </div>
    );
}
 
export default Wave;


export const AnimatedWaves = ({ color= '255, 255, 255'}) => {
    return (
        <div className="position-absolute w-100 z-index-1 bottom-0">
            <svg class="animated-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" shapeRendering="auto">
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                </defs>
                <g class="moving-waves">
                    <use xlinkHref="#gentle-wave" x="48" y="-1" fill={`rgba(${color},0.40)`}></use>
                    <use xlinkHref="#gentle-wave" x="48" y="3" fill={`rgba(${color},0.35)`}></use>
                    <use xlinkHref="#gentle-wave" x="48" y="5" fill={`rgba(${color},0.25)`}></use>
                    <use xlinkHref="#gentle-wave" x="48" y="8" fill={`rgba(${color},0.20)`}></use>
                    <use xlinkHref="#gentle-wave" x="48" y="13" fill={`rgba(${color},0.15)`}></use>
                    <use xlinkHref="#gentle-wave" x="48" y="16" fill={`rgba(${color},0.95)`}></use>
                </g>
            </svg>
        </div>
    )
}